import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  products: [],
  message: '',
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };

    default:
      return state;
  }
};

export default product;

import { combineReducers } from 'redux';

import auth from './auth';
import order from './order';
import currency from './currency';
import domain from './domain';
import product from './product';
import errors from './errors';
import loader from './loader';

export default combineReducers({
  auth,
  order,
  currency,
  domain,
  product,
  errors,
  loader,
});

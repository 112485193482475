import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { TransferDomainSection } from '../components/TransferDomainSection';
import { PageLoader } from '../components/PageLoader';

export const TransferDomain = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <TransferDomainSection />
    </>
  );
};

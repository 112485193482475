export const domain_ext = {
  success: true,
  products: [
    {
      id: '6',
      type: '9',
      name: '.com',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 2503,
        pending: 542,
        provisioned: 1004,
        suspended: 16,
        cancelled: 252,
        terminated: 689,
      },
    },
    {
      id: '7',
      type: '9',
      name: '.net',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 243,
        pending: 54,
        provisioned: 116,
        suspended: 2,
        cancelled: 2,
        terminated: 69,
      },
    },
    {
      id: '8',
      type: '9',
      name: '.org',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 494,
        pending: 79,
        provisioned: 230,
        suspended: 7,
        cancelled: 8,
        terminated: 170,
      },
    },
    {
      id: '10',
      type: '9',
      name: '.mobi',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 5,
        pending: 4,
        provisioned: 0,
        suspended: 0,
        cancelled: 0,
        terminated: 1,
      },
    },
    {
      id: '13',
      type: '9',
      name: '.biz',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 43,
        pending: 17,
        provisioned: 7,
        suspended: 0,
        cancelled: 1,
        terminated: 18,
      },
    },
    {
      id: '15',
      type: '9',
      name: '.info',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 28,
        pending: 9,
        provisioned: 8,
        suspended: 0,
        cancelled: 0,
        terminated: 11,
      },
    },
    {
      id: '94',
      type: '9',
      name: '.cm',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 2,
        pending: 0,
        provisioned: 2,
        suspended: 0,
        cancelled: 0,
        terminated: 0,
      },
    },
    {
      id: '97',
      type: '9',
      name: '.de',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 7,
        pending: 1,
        provisioned: 5,
        suspended: 0,
        cancelled: 1,
        terminated: 0,
      },
    },
    {
      id: '158',
      type: '9',
      name: '.tv',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 1,
        pending: 0,
        provisioned: 1,
        suspended: 0,
        cancelled: 0,
        terminated: 0,
      },
    },
    {
      id: '159',
      type: '9',
      name: '.rw',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
      stats: {
        total: 2,
        pending: 2,
        provisioned: 0,
        suspended: 0,
        cancelled: 0,
        terminated: 0,
      },
    },
    {
      id: '160',
      type: '9',
      name: '.co.rw',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
    },
    {
      id: '161',
      type: '9',
      name: '.org.rw',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
    },
    {
      id: '162',
      type: '9',
      name: '.ac.rw',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
    },
    {
      id: '163',
      type: '9',
      name: '.net.rw',
      stock: '0',
      paytype: 'DomainRegular',
      description: '',
      m_setup: '0',
      q_setup: '0',
      s_setup: '0',
      a_setup: '0',
      b_setup: '0',
      t_setup: '0',
      d_setup: '0',
      w_setup: '0',
      h_setup: '0',
      p4_setup: '0.00',
      p5_setup: '0.00',
      h: '0',
      w: '0',
      d: '0',
      m: '0',
      q: '0',
      s: '0',
      a: '0',
      b: '0',
      t: '0',
      p4: '0.00',
      p5: '0.00',
      qty: '0',
      visible: '1',
      ptype: 'DomainsType',
      tags: [],
    },
  ],
  call: 'getProducts',
  server_time: 1646135866,
};

export const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const faqs = [
  {
    id: 1,
    title: 'What is Shared Web hosting?',
    description:
      "In Shared Web Hosting, multiple clients are hosted on a single server i.e. the clients share the server's resources. This helps reduce the cost, since the cost of the server and its resources are spread over all the clients/packages hosted on the server. Shared Hosting is perfect for personal websites, small and mid-sized businesses that do not require all the resources of a server.",
  },
  {
    id: 2,
    title: 'Can I host multiple Web sites within one Shared Hosting plan?',
    description:
      'Yes! Our shared hosting plans allow you to host more than one Website, by adding secondary domains through your hosting control panel i.e. cPanel.',
  },
  {
    id: 3,
    title: 'Is there a Money Back Guarantee?',
    description: 'Yes, we offer a 100% Risk Free, 30 day Money Back Guarantee.',
  },
  {
    id: 4,
    title: 'Is Email hosting included in my package?',
    description:
      'Yes, all our Hosting packages come with Unlimited Email Hosting.',
  },
  {
    id: 5,
    title: 'Can I upgrade to a higher plan?',
    description:
      'Yes, you can easily upgrade to one of our higher plans at any time.',
  },
  {
    id: 6,
    title: 'Is my data safe? Do you take backups?',
    description:
      'Yes, your data is a 100% secure and we have periodic general server Backup. However you can backup manually from your cPanel. The Ultimate Plan Provides automated Backups for you.',
  },
  {
    id: 7,
    title: 'Do you include protection from viruses?',
    description: 'Yes, all our servers are protected by Clam AV.',
  },
  {
    id: 8,
    title: 'Can I divide my Shared Hosting package and resell it?',
    description:
      'While a Shared Hosting package cannot be used for this purpose, you can easily resell custom packages with our Reseller Hosting. Contact us for more details.',
  },
  {
    id: 9,
    title: 'Do you offer SSH access?',
    description:
      ' Yes, we provide SSH access to your domain. Because this is a shared environment, you will not get root access. However, you will be able to achieve most of your requirements by having the rights to access only the files relevant to your domain.',
  },
  {
    id: 10,
    title: 'Who do I get in touch with if I need help?',
    description:
      'Our Support team is always at hand to assist you. Contact us anytime.',
  },
];

export const services = [
  {
    id: 1,
    product_id: 1,
    name: 'Premium Support',
    description:
      'Our friendly and knowledgeable support team is available to help you, just contact support with any issue.',
    lang: 'en',
    important: true,
  },
  {
    id: 2,
    product_id: 1,
    name: '250GB SSD Disk space',
    description:
      'Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.',
    lang: 'en',
    important: true,
  },

  {
    id: 4,
    product_id: 1,
    name: 'Unlimited Sub-domains',
    description:
      'No limits to the number of sub domains you can add to your website.',
    lang: 'en',
    important: false,
  },
  {
    id: 5,
    product_id: 1,
    name: 'Unlimited Mail Accounts',
    description: 'No limits to the number of mail accounts you can have.',
    lang: 'en',
    important: false,
  },
  {
    id: 6,
    product_id: 1,
    name: 'Unlimited Bandwidth',
    description:
      'We don’t limit the amount of visitor traffic a website can receive or the amount of content you can upload to your website so long as you comply with our Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of our servers, we will notify you to take corrective action and may restrict the resources or ask you to choose a plan more suitable to your needs.',
    lang: 'en',
    important: false,
  },
  {
    id: 7,
    product_id: 1,
    name: 'Unlimited MySQL Databases',
    description: 'No limit to the number of databases you can create.',
    lang: 'en',
    important: false,
  },

  {
    id: 8,
    product_id: 1,
    name: 'FREE SSL Certificate',
    description:
      'We provide you with free SSL certificates through CloudFare which you can activate from inside your cPanel.',
    lang: 'en',
    important: false,
  },
  {
    id: 9,
    product_id: 1,
    name: 'FREE cPanel',
    description:
      'Control your website using free cPanel control panel and special free web hosting admin tools.',
    lang: 'en',
    important: false,
  },
  {
    id: 1,
    product_id: 2,
    name: 'Premium Support',
    description:
      'Our friendly and knowledgeable support team is available to help you, just contact support with any issue.',
    lang: 'en',
    important: true,
  },
  {
    id: 2,
    product_id: 2,
    name: '500GB SSD Disk space',
    description:
      'Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.',
    lang: 'en',
    important: true,
  },
  {
    id: 3,
    product_id: 2,
    name: '5 Websites (addon domains)',
    description: 'You can add upto 5 website to your cPanel account.',
    lang: 'en',
    important: true,
  },
  {
    id: 4,
    product_id: 2,
    name: 'Unlimited Sub-domains',
    description:
      'No limits to the number of sub domains you can add to your website.',
    lang: 'en',
    important: false,
  },
  {
    id: 5,
    product_id: 2,
    name: 'Unlimited Mail Accounts',
    description: 'No limits to the number of mail accounts you can have.',
    lang: 'en',
    important: false,
  },
  {
    id: 6,
    product_id: 2,
    name: 'Unlimited Bandwidth',
    description:
      'We don’t limit the amount of visitor traffic a website can receive or the amount of content you can upload to your website so long as you comply with our Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of our servers, we will notify you to take corrective action and may restrict the resources or ask you to choose a plan more suitable to your needs.',
    lang: 'en',
    important: false,
  },
  {
    id: 7,
    product_id: 2,
    name: 'Unlimited MySQL Databases',
    description: 'No limit to the number of databases you can create.',
    lang: 'en',
    important: false,
  },

  {
    id: 8,
    product_id: 2,
    name: 'FREE SSL Certificate',
    description:
      'We provide you with free SSL certificates through CloudFare which you can activate from inside your cPanel.',
    lang: 'en',
    important: false,
  },
  {
    id: 9,
    product_id: 2,
    name: 'FREE cPanel',
    description:
      'Control your website using free cPanel control panel and special free web hosting admin tools.',
    lang: 'en',
    important: false,
  },
  {
    id: 1,
    product_id: 3,
    name: 'Dedicated Support',
    description:
      'A Dedicated Support expert will always monitor your hosting and help you manage your hosting needs and goals so you can focus on your business.',
    lang: 'en',
    important: true,
  },
  {
    id: 2,
    product_id: 3,
    name: 'Dedicated IP address',
    description:
      'Benefit from Higher On-Demand Website Access, Improve Email Deliverability, High Level of Security, High Level of Uptime with a Dedicated IP address on this package.',
    lang: 'en',
    important: true,
  },
  {
    id: 3,
    product_id: 3,
    name: 'Unlimited SSD Disk space',
    description:
      'Unlimited Storage allows you to use and grow your web hosting account with Cloudhost without incurring any additional storage fees. Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.',
    lang: 'en',
    important: true,
  },
  {
    id: 4,
    product_id: 3,
    name: 'Unlimited Websites (addon domains)',
    description:
      'Unlimited Storage allows you to use and grow your web hosting account with Cloudhost without incurring any additional storage fees. Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.',
    lang: 'en',
    important: true,
  },
  {
    id: 5,
    product_id: 3,
    name: 'Unlimited Sub-domains',
    description:
      'No limits to the number of sub domains you can add to your website.',
    lang: 'en',
    important: false,
  },
  {
    id: 6,
    product_id: 3,
    name: 'Unlimited Mail Accounts',
    description: 'No limits to the number of mail accounts you can have.',
    lang: 'en',
    important: false,
  },
  {
    id: 7,
    product_id: 3,
    name: 'Unlimited Bandwith',
    description:
      'We don’t limit the amount of visitor traffic a website can receive or the amount of content you can upload to your website so long as you comply with our Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of our servers, we will notify you to take corrective action and may restrict the resources or ask you to choose a plan more suitable to your needs.',
    lang: 'en',
    important: false,
  },
  {
    id: 8,
    product_id: 3,
    name: 'Unlimited MySQL',
    description: 'Unlimited MySQL Databases',
    lang: 'en',
    important: false,
  },

  {
    id: 9,
    product_id: 3,
    name: 'Daily Automated Backups',
    description:
      'With Daily Automated Backup you can rest easy knowing you have a backup plan incase you are faced with lost or corrupted files.',
    lang: 'en',
    important: false,
  },
  {
    id: 10,
    product_id: 3,
    name: 'FREE SSL Certificate',
    description:
      'We provide you with free SSL certificates through CloudFare which you can activate from inside your cPanel.',
    lang: 'en',
    important: false,
  },
  {
    id: 11,
    product_id: 3,
    name: 'FREE cPanel',
    description:
      'Control your website using free cPanel control panel and special free web hosting admin tools.',
    lang: 'en',
    important: false,
  },
];

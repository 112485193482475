import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  SignIn,
  clearMessage,
  CheckEmail,
  VerifyAccToken,
} from '../redux/actions/auth';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clearErrors } from '../redux/actions/errors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '30px !important',
    height: '30px !important',
    color: '#4580F9 !important',
    margin: '0px auto !important',
  },
  mTop: {
    marginTop: '15%',
  },
  Center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '5px solid #FFFF00',
    padding: '10px',
  },
}));

export const VerifyToken = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.auth);
  let { token } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(clearErrors());
      dispatch(clearMessage());
      dispatch(VerifyAccToken({ token: token }));
    }
  }, []);

  useEffect(() => {
    if (message === 'token match') {
      navigate('/complete-order');
    }
  }, [message]);

  return (
    <section className="section">
      <div className={`${classes.mTop} container`}>
        <div className="row">
          <div className="col-md-4 m-auto">
            <div className="card">
              <div className="card-body text-center">
                <div>
                  <CircularProgress className={classes.loaderStyle} />
                </div>
                <div>Verifying...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

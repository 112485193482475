import axios from '../../axios';
import ls from 'localstorage-slim';
import { types } from './types';
import { setErrors, clearErrors } from './errors';
import { openLoader, closeLoader } from './loader';

export const clearMessage = () => {
  return {
    type: types.CLEAR_MESSAGE,
  };
};

// Action for checking domain
export const CheckDomain = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/domain-check', { domain: payload });
    dispatch({
      type: types.CHECK_DOMAIN_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for registering a domain
export const RegisterDomain = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response1 = await axios.post(
      'https://cloudhost.rw/epp/api/create-contact',
      payload.contact_data,
      {
        header: { 'Access-Control-Allow-Headers': '*' },
      }
    );
    const response2 = await axios.post(
      'https://cloudhost.rw/epp/api/create-domain',
      payload.contact_data,
      {
        header: { 'Access-Control-Allow-Headers': '*' },
      }
    );
    // console.log({ response1 });
    // console.log({ response2 });
    // dispatch({
    //   type: types.REGISTER_DOMAIN_SUCCESS,
    //   payload: response1.data,
    // });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for sending dns notification
export const SendDnsNotification = (payload) => async (dispatch) => {
  let data = {
    email: payload.email,
    firstname: payload.firstname,
    lastname: payload.lastname,
  };
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/email/dns-notification', data);
    dispatch({
      type: types.SEND_DNS_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action to set domain details state
export const SetDomainDetails = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_DOMAIN_DETAILS_SUCCESS,
    payload: payload,
  });
};

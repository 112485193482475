import React, { useState } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';

export const WhiteTopCountryBtn = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <>
      <div className="border d-lg-block d-none p-1 pl-2 pr-2 rounded-pill">
        <div
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          className={`dropdown d-inline-block mb-4 mb-lg-0 ${
            openDropdown === true && 'show'
          }`}
        >
          <Link
            id="langsDropdown"
            to=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            className="dropdown-toggle footer-language-link"
          >
            <img
              src="/assets/images/flags/united-states-of-america.svg"
              alt="USA Flag"
              className="language-flag"
            />{' '}
            English
          </Link>
          <div
            aria-labelledby="langsDropdown"
            className={`dropdown-menu dropdown-menu-center ${
              openDropdown === true && 'show'
            }`}
          >
            <Link to="" className="dropdown-item text-gray text-sm">
              <img
                src="/assets/images/flags/united-states-of-america.svg"
                alt="USA Flag"
                className="language-flag"
              />{' '}
              English
            </Link>
            {/* <Link to="" className="dropdown-item text-gray text-sm">
              <img
                src="/assets/images/flags/france.svg"
                alt="Flag"
                className="language-flag"
              />{' '}
              French
            </Link>
            <Link to="" className="dropdown-item text-gray text-sm">
              <img
                src="/assets/images/flags/germany.svg"
                alt="Flag"
                className="language-flag"
              />{' '}
              German
            </Link>
            <Link to="" className="dropdown-item text-gray text-sm">
              <img
                src="/assets/images/flags/spain.svg"
                alt="Flag"
                className="language-flag"
              />{' '}
              Spanish
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

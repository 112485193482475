import React from 'react';

export const TermsSection = () => {
  return (
    <section className="section">
      <div className="container mt-6">
        <div className="row">
          <div className="col-md-6 mx-auto text-center mb-4">
            <h2 className="title mt-5 font-weight-bold">Terms of service</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 m-auto">
            <p>
              Agreement between CLOUDHOST<sup>®</sup> and business or
              individual. The client/individual is subject to the following
              terms and conditions.
            </p>
            <h5>GENERAL WORKING AGREEMENT</h5>
            <p>
              This document defines the terms and conditions of our working
              relationship. All projects or services that CLOUDHOST® (hereafter
              referred to as “CLOUDHOST” or “we”) may be contracted to produce
              or provide for you (hereafter referred to as “you” or “the
              client”) will be subject to the following:
            </p>
            <h5>SUMMARY</h5>
            <p>
              You must agree to the below terms of service before you can create
              your CLOUDHOST Web Hosting account. Our website will not permit
              you to make any Order unless you agree to the below terms of
              service.
            </p>
            <p>
              Our terms of service (TOS) are designed to provided to our
              customers a clear and transparent understanding of what we expect
              of them while they are using our service(s). The use of CLOUDHOST
              service(s) represents your acceptance and agreement to CLOUDHOST’s
              (TOS), regardless of whether you have read them or not
            </p>

            <p>
              We accept your use of our service(s); therefore we do not actively
              monitor your accounts activity under normal conditions.
              Furthermore, we do not implement editorial control over the
              content of any Web site. This includes e-mail sending, news group
              or other material created or available over or through the
              services, except for certain proprietary Web sites. However, in
              accordance with our (TOS), we hold full authority to remove any
              materials that, in our individual judgment, may be illegal, may
              subject us to liability or which may violate our (TOS). CLOUDHOST
              reserves the right to oblige with legal establishment and/or third
              parties in the examination of any suspected or alleged crime or
              civil wrongdoing. Violation of our (TOS) may result in the
              suspension or immediate termination of your CLOUDHOST Hosting
              account and/or services.
            </p>

            <p>
              We do not routinely monitor the activity of accounts except for
              measurements of system exploitation, possible security or fraud
              risks and the preparation of billing records. However, in our
              efforts to promote good citizenship within the Internet society,
              we will respond appropriately if we become aware of improper use
              of our service.
            </p>

            <p>
              If a CLOUDHOST Web Hosting account is used to violate our (TOS) we
              reserve the right to cease your service without notice. We prefer
              to advise customers of inapt activities and any necessary
              corrective action. However, blatant violations of the (TOS) will
              result in instantaneous termination of service. Our failure to
              implement this policy, for whatever reason, shall not be
              interpreted as a waiver of our right to do so at any time.
            </p>

            <p>
              As a member of our hosting community, you must use your hosting
              account access ethically and responsibly.
              <ul>
                <li>
                  Uptime Guarantee is for the time the server is connected to
                  the internet & connected to power, it does not include the
                  time the server is down for a reboot, hardware
                  upgrades/downgrades, or for downtime caused by
                  software/hardware errors or issues.
                </li>
              </ul>
            </p>

            <h5>ACCOUNT SETUP</h5>

            <p>
              A product or service must be purchased from CLOUDHOST.com to allow
              a user to access the client area. Any product(s) or service(s)
              that a user registers with, must be paid for within two (2)
              working days or any orders will be terminated along with the users
              inactive account
            </p>

            <p>
              We will setup your account after we have received payment and we
              and/or our payment partner(s) have screened the order(s) in case
              of fraud. All new orders are usually activated within 24 hours. If
              you have not been activated within this time frame, please click
              here to contact our billing department.
            </p>

            <p>
              CLOUDHOST can not give any guarantee on a setup time of which
              clients order(s) will be activated. While most orders will be
              activated within 1-12 hours, CLOUDHOST can not guarantee setup
              within this time as all orders are processed and activated
              manually.
            </p>

            <p>
              Client will be held responsible for all actions performed by their
              account whether it be done by them or by others. If server
              security is compromised, the account holder is responsible for all
              violations of the terms of service. Client is also responsible for
              disconnect and reconnect fees associated with violations.
            </p>

            <h5>CONTENT & ACTIVITIES</h5>

            <p>
              All services provided by CLOUDHOST Web Hosting may only be used
              for lawful purposes. This includes, but not limited to:
              copyrighted material, material we judge to be threatening or
              obscene. The customer agrees to indemnify and hold harmless
              CLOUDHOST from any claims resulting from the use of our services.
            </p>

            <h5>EXAMPLES OF TOTALLY UNACCEPTABLE MATERIAL</h5>

            <ul>
              <li>IRC or IRC egg drops or bots.</li>
              <li>Mail bombers.</li>
              <li>IP spoofers.</li>
              <li>
                Video & Game hosting/streaming (excluding Flash & Java Games,
                third party streaming is permitted).
              </li>
              <li>
                Illegal distributing and/or advertising of copyrighted material.
              </li>
              <li>Warez/Nulled Content.</li>
              <li>XXX/Porn Hosting or Linking is not allowed.</li>
              <li>Websites designed specifically for downloads.</li>
              <li>PHPizabi scripts.</li>
              <li>YaBB Forums.</li>
              <li>iProber.</li>
              <li>
                Vulgar language used to offend a minority, ethnic society,
                sexual orientation prejudiced, discrimination against a group,
                sex, ethnic and orientation etc.
              </li>
              <li>High memory uses Scripts.</li>
              <li>ROMs and Emulators.</li>
              <li>Free Hosting or Blog Hosting.</li>
              <li>Forum Hosting.</li>
              <li>Proxy Sites.</li>
            </ul>
            <h5>DISTRIBUTION OF MALICIOUS CODE</h5>

            <p>
              Intentional distributions of software that attempts to and/or
              causes damage, harassment, or annoyance to persons, data and/or
              computer systems are prohibited. Examples of this are computer
              viruses, Trojan-horse programs and Internet-based worms. Such an
              offense will result in the immediate termination of the offending
              account.
            </p>

            <h5>INFRINGEMENT</h5>

            <p>
              Infringement of Copyright, Patent, Trademark, Trade Secret, or
              Intellectual Property Right: Distribution and/or posting of
              copyrighted or the aforementioned infringements will not be
              tolerated. It is not permitted to use of the CLOUDHOST to transmit
              any material (by e-mail, uploading, posting or otherwise) that
              infringes any copyright, trademark, patent, trade secret or other
              proprietary rights of any third party, including, but not limited
              to, the unauthorized copying of copyrighted material, the
              digitization and distribution of photographs from magazines,
              books, or other copyrighted sources and the unauthorized
              transmittal of copyrighted software
            </p>

            <h5>FILE HOSTING</h5>

            <p>
              File hosting and file storage of any type is strictly prohibited
              on any and all servers within the CLOUDHOST Web Hosting network.
              You MAY NOT use your CLOUDHOST account as a download or file
              storage repository. Our services are strictly offered for website
              hosting only. This does NOT mean that you can not have downloads
              on your site. This means you can not operate a site that
              specializes in downloads like Download.com or similar and you may
              not use your CLOUDHOST account as storage repository for MP3,
              games, video, audio or music files. Your account may not exceed
              10% of used space in MP3, games, video, audio or music files.
            </p>

            <h4>LIMITATIONS</h4>

            <ul>
              <li>Memory usage may not exceed 20% per Domain.</li>
              <li>CPU usage may not exceed 25% per Domain.</li>
              <li>Apache connections may not exceed 25.</li>
              <li>MySQL user connections may not exceed 50.</li>
              <li>50 IMAP mail connections per IP.</li>
              <li>50 POP3 mail connections per IP.</li>
              <li>40 outgoing emails per hour.</li>
            </ul>
            <p>
              The above limitations may vary depending on which server you are
              hosted on. Please contact us for the exact limitations for each
              server in the network.
            </p>

            <h5>ADULT SITES</h5>
            <p>
              Due to various legal and moral implications. CLOUDHOST does not
              permit sexually explicit material on any of its servers.
            </p>

            <h5>SPAM / EMAIL POLICY</h5>
            <p>
              We take a zero tolerance stance against sending of unsolicited
              e-mail, bulk emailing, and spam. “Safe lists” and “double Opt-in”
              will be treated as spam. Any user who sends out spam will have
              their account terminated without notice.
            </p>
            <p>
              CLOUDHOST Web Hosting reserves the right to require changes or
              disable as necessary any web site, account, database, or other
              component that does not comply with this policy, at its sole
              discretion. CLOUDHOST also reserves the right to make any such
              modifications in an emergency at our sole discretion.l
            </p>

            <p>
              CLOUDHOST reserves the right to charge the holder of the account
              used to send any unsolicited e-mail a clean up fee. This cost of
              the clean up fee is left entirely to the discretion of CLOUDHOST.
            </p>

            <p>
              Bulk Email / Mass Email scripts of any type are not permitted on
              any CLOUDHOST server. The send mail rate on all CLOUDHOST servers
              is capped at 200 emails per hour per account, any more than this
              will be bounced back to the sender. If you require more than this,
              please open a support ticket stating the reason why the 200 limit
              should be raised on your account and the EXACT source of your
              mailing list.
            </p>

            <h5>USENET SPAMMING</h5>

            <p>
              CLOUDHOST Web Hosting has a zero tolerance policy for the use of
              its servers for the posting of messages or commercial
              advertisements, which violate the rules, regulations, FAQ or
              charter of any newsgroups or mailing list. Commercial messages
              that are appropriate under the rules of a newsgroup or mailing
              list or that are solicited by the recipients are permitted.
            </p>

            <h5>PAYMENT INFORMATION</h5>

            <p>
              You agree to supply appropriate payment for the services received
              from CLOUDHOST Web Hosting, in advance of the time period during
              which such services are provided. You agree that until and unless
              you notify CLOUDHOST of your desire to cancel any or all services
              received, those services will be billed on a recurring basis.
            </p>

            <h5> Unpaid bills UNPAID BILLS </h5>

            <p>
              You agree that we may, in our sole discretion if you have any due
              bills irrespective of the service rendered or of the amount owed,
              all your accounts are liable to be suspended without your
              notification and all of these services offered to you by GlobexCam
              Company Limited not limiting; Web SMS (Bulk SMS), Online
              Marketing, Web Hosting, Domain Name, Virtual Phone Number (DID).
            </p>

            <h5>BACKUPS AND DATA LOSS BACKUPS </h5>
            <p>
              Your use of the CLOUDHOST Web Hosting service is at your sole
              risk. CLOUDHOST is not responsible for any and all files and data
              residing on your account on our servers. CLOUDHOST does not
              maintain backup copies of customers web sites or e-mail. CLOUDHOST
              cannot guarantee that the contents of a web site will never be
              deleted or corrupted, or that a backup of a web site will always
              be available. You agree to take full and sole responsibility for
              any and all files and data transferred to our servers and to
              maintain all appropriate backups of any any and all files and data
              stored on any CLOUDHOST server to which you have an account on.
            </p>
            <h5>TERMINATION OF DATA AND CUSTOMER PRIVACY</h5>

            <p>
              For your privacy, all data and files from terminated and canceled
              accounts are immediately deleted and purged from our servers. This
              includes any and all back ups. Please make sure to back up all
              your files and data before canceling your CLOUDHOST account.
            </p>
            <h5> EMERGENCY AND SPECIAL SITUATIONS </h5>

            <p>
              We reserve the right to take any proactive steps we deem necessary
              to maintain the security, connectivity, performance and services
              of our network, infrastructure and customers, including but not
              limited to actions requiring us to deactivate web sites, shut down
              our network, or review the content on web sites to evaluate the
              reason for perceived behaviors. In rare instances, we may need to
              completely delete a web site or account because of some
              significant failure, security breach or other emergency. In such
              cases, we do our best to backup a deleted web site, but we cannot
              ever guarantee a full restore of a web site.
            </p>
            <h5> Account Security </h5>

            <p>
              Misuse of your account is your sole responsibility, even if your
              friends, family member, guest, or employee committed the activity.
              Henceforth, you must take actions to ensure that others do not
              gain access, which you do not approve of, to your account. In
              addition, you may not use your account to breach security of
              another account or attempt to gain unauthorized access to another
              network or server.
            </p>
            <p>
              Your password provides access to your account. It is your
              responsibility to keep your password secure. We are not
              responsible for ANY misuse of your account due to the authorized
              or unauthorized distribution of your password.
            </p>
            <p>
              Sharing your password and account access with unauthorized users
              is prohibited. You should take precautions to prevent others from
              using your account since you will be held responsible for such
              use.
            </p>
            <p>
              Attempting to obtain another user’s account password is strictly
              prohibited and may result in termination of service. You must
              adopt adequate security measures to prevent or minimize
              unauthorized use of your account.
            </p>
            <p>
              Users who violate systems or network security may incur unlawful
              or civil liability. CLOUDHOST will oblige fully with examinations
              of violations of systems or network security at other sites,
              including cooperating with law enforcement authorities in the
              investigation of suspected criminal violations.
            </p>

            <h5> CANCELLATION AND REFUND POLICY </h5>
            <p>
              CLOUDHOST does not have a refund policy and does not offer a money
              back guarantee of any type. All sales are considered final. We do
              not prorate any part of our billing fees for any unused time.
            </p>
            <p>
              You may cancel your CLOUDHOST account at any time you desire. If
              you joined with PayPal, please login to your PayPal account and
              cancel your subscription agreement. Alternatively, Click Here to
              submit a cancellation request to our customer support help desk.
            </p>
            <p>
              If you cancel your CLOUDHOST account you are entitled to keep the
              domain that we have registered for you on your behalf. We will
              also transfer it to any web hosting company or domain registrar of
              your choice completely free of charge.
            </p>
            <p>
              If you joined CLOUDHOST via the yearly payment option and you
              cancel before the year has expired, your CLOUDHOST account will
              remain active until exactly 365 days have passed from the date of
              your last yearly Payment. After which it will be terminated unless
              you renew your billing before the 365 days has expired.
            </p>
            <p>
              If you joined CLOUDHOST via the monthly payment option. Your
              account will be terminated 30 days after your last payment was
              received by CLOUDHOST.
            </p>
            <p>
              CLOUDHOST will treat all files and data of terminated accounts
              exactly as outlined in the above clause 5.1.
            </p>

            <h5>CANCELLATION FOR LACK OF PAYMENT</h5>
            <p>
              Any account that has unpaid invoices that are more than 3 days
              outstanding may be terminated at any time without notice. Before
              CLOUDHOST will terminate any account for lack of payment,
              CLOUDHOST will send no less than 2 payment request emails to the
              account holder. CLOUDHOST will treat all files and data of
              terminated accounts exactly as outlined in the above clause 5.1.
            </p>

            <h5> REFUSAL OF SERVICE </h5>
            <p>
              CLOUDHOST reserves the right to refuse service at its discretion.
              CLOUDHOST will treat all files and data of terminated accounts
              exactly as outlined in the above clause 5.1.
            </p>

            <h5> DOMAIN NAMES </h5>
            <p>
              All Domain name As required by ICANN, we will release all client
              information to our domain registar, and it will be automatically
              set as the Administrative, Billing and Technical information.
              Client reserves the right to contact support and get this
              information changed. All information (Admin, Billing, Tech, etc.)
              on domain profiles must be 100% true as required by ICANN. If we
              discover false information, we may suspend access to your domain
              and/or hosting.
            </p>
            <h5>DOMAIN NAME TRANSFER </h5>
            <p>
              Some CLOUDHOST customers are entitled to one free domain transfer
              or one free domain name as stated in the below (8.2) per web
              hosting account.
            </p>
            <h5> COMPLIMENTARY FREE DOMAIN NAMES </h5>
            <p>
              Some new CLOUDHOST web Hosting accounts that select annual or
              biennial billing are entitled to one complimentary free domain
              name registration for a period of one year. This domain name must
              be selected during the initial automated sign up process. If you
              do not select a domain name at this time, you will automatically
              forfeit any claims to any complimentary registration that was
              available to you during the sign up process. You may have only one
              complimentary domain name registration with every new CLOUDHOST
              web hosting account provided that you request it during the
              initial automated sign up process.
            </p>
            <p>
              <em>Please Note :</em> The complimentary domain period is valid
              only for one year only. After the complimentary year has expired
              you are solely responsible for the renewal fees of your domain
              name.
            </p>

            <h5> DOMAIN EXTENSIONS</h5>
            <p>
              The only complimentary domain name extension that we provide for
              free for the first year are the ones list exactly below.
            </p>
            <p>.com / .net / .org / .info / .biz / .mobi</p>

            <h5> CLOUDHOST'S DOMAIN NAME TRANSFER AWAY POLICY </h5>
            <p>
              You may transfer away from CLOUDHOST any domain name that you have
              purchased via the CLOUDHOST online ordering system to any other
              web hosting company or domain registrar at any time you desire
              providing the domain name is fully paid up, has not been refunded,
              has not been charged back and does not have any active or
              outstanding invoices due and is not in redemption.
            </p>

            <h5>Search engine submission SEARCH ENGINE SUBMISSION</h5>
            <p>
              Depending on the submission package you purchase, CLOUDHOST will
              submit your website to 100-300 directories (Google, Yahoo and Bing
              included). Our responsibility is for submission only. We do not
              guarantee that Google or any other search engine or directory will
              index and list your website in a timely manner. Google, Yahoo,
              etc… Are completely separate companies from CLOUDHOST and we do
              not have any control or influence over their final decision to
              accept or decline your website.
            </p>

            <h5>RESSOURCE USAGE</h5>
            <p>Users may not initiate the following:</p>
            <ol>
              <li>
                Use 5% or more of system resources (This included CPU and
                memory) There are numerous activities that could cause such
                problems; these include: CGI scripts, FTP, PHP, HTTP, etc. If
                this clause is breached it will trigger an auto kill failsafe
                mechanism on the server which will auto suspend the violating
                account.
              </li>

              <li>
                Run any type of interactive real-time chat applications that
                require server resources. Remotely-hosted services are
                permitted.
              </li>

              <li>
                {' '}
                Run stand-alone, unattended server-side processes at any point
                in time on the server. This includes any and all daemons, such
                as IRCD.
              </li>

              <li>
                {' '}
                Run any software that interfaces with an IRC (Internet Relay
                Chat) network.
              </li>

              <li>
                Offer services to third parties that are run off of CLOUDHOST’s
                servers.
              </li>

              <li>
                {' '}
                Run any gaming servers or games scripts for single or multi
                player games.
              </li>

              <li>
                {' '}
                Run an email server offering free or paid email accounts to the
                general public. Example: Hotmail, Yahoo Mail, GMail ,etc…
              </li>

              <li>
                Operate a online radio and or movie service that uses our
                servers.
              </li>
            </ol>

            <h5> BANDWIDTH USAGE</h5>
            <p>
              You are allocated a specified monthly bandwidth allowance per
              hosting package.
            </p>

            <h5> SHARED WEB HOSTING</h5>

            <p>
              CLOUDHOST’s standard web hosting offer (permits) you to host
              unlimited domain names under a single account with a single
              control panel.
            </p>
            <p>
              This means that your account has one master domain name and all
              additional domain names are considered add-on domains. All your
              domain names must be administered from a single control panel.
              Only one control panel is allowed per account.
            </p>
            <p>You are not permitted to…</p>
            <ol>
              <li>
                Resell web hosting space. (This is not a reseller account)
              </li>

              <li>
                {' '}
                Create accounts for third parties. All domain names that are in
                your control panel must be under your direct control and
                administration. You are not permitted to create accounts for
                third parties on you account.
              </li>
              <li>
                {' '}
                Have multiple control panels (cPanel’s) or (WHM) Web Hosting
                Manager on a standard account.
              </li>
            </ol>

            <h5> CUSTOMER KNOWLEDGE & RESPONSIBILITIES</h5>
            <p>
              Use of CLOUDHOST’s web hosting services requires a certain level
              of knowledge in the use of certain internet languages, slang,
              jargon, terminology, protocols, software, ETC… The level of
              knowledge customer required to have varies greatly and depends on
              the customers anticipated use and desired content and
              functionality of his/hers website. The customer agrees that he/she
              has the necessary knowledge to create his/hers website with the
              desired content, look and functionality. The customer agrees that
              it is not the responsibility of CLOUDHOST to provide this
              knowledge or to create, design, program or help in the designing,
              creation, programming or publishing of the customers website.
            </p>

            <h5> SSH, ROOT AND SHELL ACCESS </h5>

            <p>
              We do not permit Shell, Telnet, SSH or root access to any of our
              servers.
            </p>

            <h5> PRICE CHANGE </h5>
            <p>
              The amount you pay for hosting will never increase from the date
              of purchase unless the amount is on promotion. We reserve the
              right to change prices listed on CLOUDHOST, and the right to
              increase the amount of resources given to plans at any time.
            </p>
            <h5> AUTO REBILLING</h5>
            <p>
              For your convenience your CLOUDHOST account will auto rebill on a
              monthly, annually or tri-annual basis, (depending on which billing
              package you have selected at time of purchase). If you do not want
              your account to auto rebill, you must notify us at least 2
              calendar days (48 hours) before the date that the re-billing is
              due to take place. Failure on your part to notify us of your
              desire not to be rebilled, will result in your account being
              rebilled and you agree to hold harmless and indemnify CLOUDHOST
              from any and all claims of wrongful billing.
            </p>

            <h5> INDEMNIFICATION </h5>

            <p>
              Customer agrees that it shall defend, indemnify, save and hold
              CLOUDHOST harmless from any and all demands, liabilities, losses,
              costs and claims, including any and all attorney’s fees asserted
              against CLOUDHOST, its agents, its customers, officers and
              employees, that may arise or result from any service provided or
              performed or agreed to be performed or any product sold by
              customer, its agents, employees or assigns. Customer agrees to
              defend, indemnify and hold harmless CLOUDHOST against liabilities
              arising out of;
            </p>
            <ol>
              <li>
                {' '}
                any injury to person or property caused by any products sold or
                otherwise distributed in connection with CLOUDHOST;
              </li>

              <li>
                {' '}
                any material supplied by customer infringing or allegedly
                infringing on the proprietary rights of a third party;
              </li>
              <li>
                {' '}
                copyright infringement and (4) any defective products sold to
                customers from a CLOUDHOST server.
              </li>
            </ol>

            <p>
              IF LAWSUIT(S) ARE THREATENED AGAINST GLOBEXCAM-HOST. If we are
              sued or threatened with lawsuit in connection with Service(s)
              provided to you, we may turn to you to indemnify us and to hold us
              harmless from the claims and expenses (including attorney’s fees
              and court costs). Under such circumstances, you agree that you
              will, upon demand, obtain a performance bond with a reputable
              bonding company or, if you are unable to obtain a performance
              bond, that you will deposit money with us to pay for our
              reasonably anticipated expenses in relation to the matter for the
              coming year. Such deposit will be drawn down as expenses are
              incurred, with all account notices sent to the WHOIS contact
              information provided in association with your domain names and/or
              account. We shall not be obliged to extend you any credit in
              relation to such expenses and we may terminate our services for a
              failure to make or renew such a deposit. We will return any unused
              deposit upon the later of one year from deposit or the conclusion
              of the matter.
            </p>

            <h5>DISCLAIMER </h5>
            <p>
              CLOUDHOST will not be responsible for any damages your business
              may suffer. CLOUDHOST makes no warranties of any kind, expressed
              or implied for services we provide. CLOUDHOST disclaims any
              warranty or merchantability or fitness for a particular purpose.
              This includes loss of data resulting from delays, non deliveries,
              hardware and software failures, hacking, sabotage, terrorist
              attacks, wrong delivery, Force Majore and any and all service
              interruptions caused by CLOUDHOST and its employees.
            </p>

            <h5> CHANGE TO THE (TOS) </h5>
            <p>
              CLOUDHOST reserve the right to revise, alter and update the above
              (TOS) without notice at anytime at its sole discretion and can
              apply any and all changes and updates retroactively.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { BuyDomainSection } from '../components/BuyDomainSection';
import { PageLoader } from '../components/PageLoader';

export const BuyDomain = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <BuyDomainSection />
    </>
  );
};

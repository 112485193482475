import React from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';

export const VP = () => {
  return (
    <>
      <section id="vp" className="section mt-4">
        <div className="overlay"></div>
        <div className="container mt-5 higher-index-2">
          <div className="row">
            <div className="col-md-10 m-auto pt-5 mt-5 mb-5 pb-5">
              <h1 className="text-center display-2 text-white">
                What Makes Cloudhost So Different?
              </h1>
              <p className="text-center text-white">
                With over 15+ years of hosting experience, Cloudhost is here to
                help you grow online by providing reliable and premuim web
                hosting service.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-2">
              <div className="info info-horizontal bg-white shadow">
                <div className="icon icon-shape icon-shape-primary rounded-circle text-white">
                  <i className="ni ni ni-support-16 text-primary"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title font-weight-bold">
                    Premuim and Dedicated Support
                  </h5>
                  <p>
                    Our Experts with over 15+ years of experience working with
                    clients like you are available to work with you anytime you
                    are stuck.
                  </p>
                  <a
                    href="support-details-page.html"
                    className="text-primary text-uppercase"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="info info-horizontal bg-white shadow">
                <div className="icon icon-shape icon-shape-primary rounded-circle text-white">
                  <i className="ni ni-settings text-primary"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title font-weight-bold">
                    Easy to use Control Panel
                  </h5>
                  <p>
                    Cpanel, a user friendly and most popular and hosting control
                    panel at your fingertips removes the headache of managing
                    your web hosting
                  </p>
                  <a
                    href="cpanel-details-page.html"
                    className="text-primary text-uppercase"
                  >
                    See all features
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="info info-horizontal bg-white shadow">
                <div className="icon icon-shape icon-shape-primary rounded-circle text-white">
                  <i className="ni ni-spaceship text-primary"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title font-weight-bold">
                    Fast & Reliable Servers{' '}
                  </h5>
                  <p>
                    We make sure your website is fast, secure, always up - so
                    your visitors and search engines can find and trust your
                    website.{' '}
                  </p>
                  <Link to="" className="text-primary text-uppercase">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-2">
              <div className="info info-horizontal bg-white shadow">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-hat-3 text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title font-weight-bold">
                    Hire Us - We'll Do It For You
                  </h5>
                  <p>
                    Do you want everything done for you? Our team of experienced
                    professionals and Services are available to help you setup
                    your business online.
                  </p>
                  <a
                    href="pro-services.html"
                    className="text-primary text-uppercase"
                  >
                    See Pro Services
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import React, { useEffect } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetProducts } from '../redux/actions/product';
import { CreateOrderDraft } from '../redux/actions/order';

export const ComparePlans = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const { default_currency } = useSelector((state) => state.currency);
  useEffect(() => {
    if (default_currency.hasOwnProperty('code')) {
      dispatch(GetProducts());
    }
  }, [default_currency]);

  const handleCreateOrderDraft = () => {
    dispatch(CreateOrderDraft());
  };
  return (
    <>
      <section className="section">
        <div className="container higher-index-2">
          <div className="row">
            <div className="col-md-6 ml-auto mr-auto text-center">
              <h1 className="display-2 mb-4">Compare Plans and Features </h1>
              <div className="section-space"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mx-auto">
              <div>
                <div className="card card-plain">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-pricing">
                        <thead className="">
                          <tr>
                            <th>
                              <h5 className="mb-0">Features</h5>
                            </th>
                            {products.product !== undefined &&
                              products.product.length > 0 &&
                              default_currency !== undefined &&
                              products.product.map((product) => (
                                <th className="text-center">
                                  <p className="lead font-weight-bold">
                                    {product.name}
                                  </p>
                                  <div className="display-2 d-flex justify-content-center ">
                                    {' '}
                                    <span className="font-weight-bold ">
                                      {new Intl.NumberFormat('en-US').format(
                                        product.price *
                                          (default_currency.code !== 'FCFA'
                                            ? parseFloat(default_currency.rate)
                                            : 1)
                                      )}
                                    </span>{' '}
                                    <span
                                      className="font-weight-400 pt-3 text-left"
                                      style={{
                                        fontSize: '16px',
                                        lineHeight: '1',
                                      }}
                                    >
                                      {default_currency.code} <br />
                                      Monthly
                                    </span>
                                  </div>
                                  <ul className="list-unstyled my-2">
                                    <li className="align-items-center mb-2">
                                      Renews at{' '}
                                      <strong>
                                        {new Intl.NumberFormat('en-US').format(
                                          (product !== null &&
                                          default_currency !== undefined
                                            ? product.price *
                                              (default_currency.code !== 'FCFA'
                                                ? parseFloat(
                                                    default_currency.rate
                                                  )
                                                : 1)
                                            : 0) *
                                            12 *
                                            2
                                        )}{' '}
                                        <sup
                                          style={{
                                            fontSize: '13px',
                                          }}
                                        >
                                          {default_currency.code}
                                        </sup>
                                      </strong>{' '}
                                      monthly
                                    </li>
                                    <Link
                                      onClick={() => {
                                        handleCreateOrderDraft();
                                        ls.set(
                                          'chosen_product_id',
                                          product.hostbill_id,
                                          { encrypt: true }
                                        );
                                        ls.set(
                                          'chosen_product',
                                          products.product.filter(
                                            (prod) =>
                                              prod.hostbill_id ===
                                              product.hostbill_id
                                          ),
                                          { encrypt: true }
                                        );
                                      }}
                                      to="/search-domain"
                                      className="btn btn-primary btn-round btn-lg w-100"
                                    >
                                      Order Now
                                    </Link>
                                  </ul>
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Support</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Dedicated IP Address</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>SSD Disk space</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Websites (addon domains)</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">upon request</td>
                          </tr>
                          <tr>
                            <td>Sub-domains</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Mail Accounts</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>MySQL Databases</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>FREE Domain for life</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Backups</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>FREE SSL Certificate</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>FREE cPanel</td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="badge badge-circle badge-danger">
                                <i className="fas fa-times text-white"></i>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="badge badge-circle badge-success">
                                <i className="ni ni-check-bold text-white"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  currencies: [],
  default_currency: {},
  message: '',
};

const currency = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case types.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case types.SET_DEFAULT_CURRENCY_SUCCESS:
      ls.set('default_currency', action.payload, { encrypt: true });
      return {
        ...state,
        default_currency: action.payload,
      };

    default:
      return state;
  }
};

export default currency;

import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckPaymentStatus,
  GetOrderDraft,
  MakePayment,
} from '../../redux/actions/order';
import { GetClientDetails } from '../../redux/actions/auth';
import { Alert, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';
import { GetProducts } from '../../redux/actions/product';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { MsgText } from '../MsgText';
import { BsArrowRight, BsExclamationCircleFill } from 'react-icons/bs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '20px !important',
    height: '20px !important',
    color: '#fff !important',
    margin: '0px auto !important',
  },
  _loaderStyle: {
    width: '50px !important',
    height: '50px !important',
    color: '#4580F9 !important',
    margin: '170px auto !important',
    zIndex: 1,
    position: 'absolute !important',
  },

  cardMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    filter: 'blur(8px)',
  },
  center: {
    margin: 'auto',
    width: '10%',
  },
}));

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
  const month = i + 1;
  return month <= 9 ? '0' + month : month;
});
let counter = 0;
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);
export default function CardForm({
  selectedCreditCard,
  onUpdateState,
  setIsCardFlipped,
  handleSubmitAction,
  children,
}) {
  const [errors, setErrors] = useState({
    id: '',
    cardNumber: '',
    cardHolder: '',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_details, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const { order, payment_response, payment_status } = useSelector(
    (state) => state.order
  );
  const { default_currency, currencies } = useSelector(
    (state) => state.currency
  );
  const { products } = useSelector((state) => state.product);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [city, setCity] = useState('');
  const [postcodeArea, setPostcodeArea] = useState('');
  const [postalCode, setPostalCode] = useState('');
  // Modal open state
  const [modal, setModal] = useState(false);

  const [currentPeriod, setCurrentPeriod] = useState(2);
  const [currentDPeriod, setCurrentDPeriod] = useState(1);
  const [currentDomain, setCurrentDomain] = useState(null);
  const [serviceProductId, setServiceProductId] = useState();
  const [domainProductId, setDomainProductId] = useState();
  const [showCompanyNameField, setShowCompanyNameField] = useState(true);
  const [usePreInfo, setUserPreInfo] = useState(null);
  const [hasDomain, setHasDomain] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [paymentOpt, setPaymentOpt] = useState('mobile-money');
  const [errMessage, setErrMessage] = useState('');
  const [total, setTotal] = useState(0);
  const [domainDetails, setDomainDetails] = useState(false);
  const [dProduct, setDProduct] = useState(null);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [cardPay, setCardPay] = useState(false);
  const [checkStatus, setCheckStatus] = useState(true);
  let default_currency_details = ls.get('default_currency', { decrypt: true });
  let client = ls.get('client', { decrypt: true });
  let draft_id = ls.get('order_draft', { decrypt: true });
  let intervalId = null;

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const config = {
    // RW TEST KEYS
    // public_key: 'FLWPUBK_TEST-9cb41850dd8dbab51a975e43b40ebacc-X',
    // tx_ref: 'FLWSECK_TEST-5ae31a92cf984d0502060d2d728d6c2c-X',

    // CM TEST KEYS
    // public_key: 'FLWPUBK_TEST-9cb41850dd8dbab51a975e43b40ebacc-X',
    // tx_ref: 'FLWSECK_TEST-5ae31a92cf984d0502060d2d728d6c2c-X',

    // RW LIVE KEYS
    // public_key: 'FLWPUBK-2e2c8a85f9c212d601ffa766f6cc17de-X',
    // tx_ref: 'FLWSECK-f0844722129c0e04e3830d1fc2ff524b-X',

    // CM LIVE KEYS
    public_key: 'FLWPUBK-7f96cbb3f14b363e8b1b8dc9998f4a9c-X',
    tx_ref: 'FLWSECK-aeaaf6eff124cfe1c59af329ea16879a-X',

    amount: 0,
    currency: '',
    country: '',
    payment_options: 'card',
    customer: {
      email: '',
      phonenumber: '',
      name: '',
    },
    customizations: {
      title: 'Hosting payment',
      description: 'Payment for hosting service',
      logo: 'http://cloudhost.rw/assets-cdn/images/cloudhost-icon.png',
    },
  };

  const [flutterwaveData, setFlutterwaveData] = useState(config);

  useEffect(() => {
    if (client) {
      dispatch(GetClientDetails({ client_id: client.client_id }));
    }
    if (draft_id && draft_id !== null && draft_id !== undefined) {
      dispatch(GetOrderDraft(draft_id));
    }
    dispatch(GetProducts());

    let domain_details = ls.get('domain_details', { decrypt: true });
    if (domain_details !== null && domain_details.hasOwnProperty('name')) {
      setDProduct(domain_details);
    }
  }, []);

  useEffect(() => {
    if (
      client_details &&
      client_details !== undefined &&
      client_details.hasOwnProperty('phonenumber')
    ) {
      setPhoneNumber(client_details.phonenumber.split(' ')[1]);
    }
  }, [client_details]);

  useEffect(() => {
    if (order && products) {
      if (order.success === false) {
        navigate('/');
        return;
      }
      if (order.draft !== undefined) {
        let obj_key = Object.keys(order.draft.services);
        let _obj_key = Object.keys(order.draft.domains);
        ls.set(
          'chosen_product_id',
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : '',
          { encrypt: true }
        );
        setServiceProductId(
          order.draft.services.hasOwnProperty('1')
            ? order.draft.services[obj_key[0]].product_id
            : ''
        );
        setCurrentPeriod(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1
        );

        setCurrentDPeriod(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1
        );
        ls.set(
          'current_period',
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].period
            : 1,
          { encrypt: true }
        );

        setCurrentDomain(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].name
            : order.draft.services[obj_key[0]].domain
        );
        ls.set(
          'chosen_ext',
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]].tld
            : '',
          { encrypt: true }
        );

        setDomainDetails(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]]
            : ''
        );

        setDomainProductId(
          order.draft.domains.hasOwnProperty('1')
            ? order.draft.domains[_obj_key[0]]
            : ''
        );

        let total = 0;
        if (order.draft.services.hasOwnProperty('1')) {
          products.product !== undefined &&
            default_currency !== undefined &&
            products.product
              .filter((product) => product.hostbill_id === serviceProductId)
              .map((filteredProduct) => {
                total = parseFloat(
                  filteredProduct !== null && default_currency !== undefined
                    ? filteredProduct.price *
                        parseInt(currentPeriod) *
                        (default_currency.code !== 'FCFA'
                          ? parseFloat(default_currency.rate)
                          : 1)
                    : 0
                );
              });
        } else {
          total = parseFloat(
            domainDetails !== null &&
              dProduct !== null &&
              default_currency !== undefined
              ? dProduct.register *
                  parseInt(currentDPeriod) *
                  (default_currency.code !== 'FCFA'
                    ? parseFloat(default_currency.rate)
                    : 1)
              : 0
          );
        }

        if (total > 0) {
          setTotal(total);
          setFlutterwaveData({
            ...flutterwaveData,
            amount: total,
            currency: default_currency_details.hasOwnProperty('code')
              ? default_currency_details.code
              : client_details.country,
          });
        }
      }
    }
  }, [order, products]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;

    onUpdateState(name, value);
  };

  const handlePaymentOpt = (event) => {
    setPaymentOpt(event.target.value);
  };

  const handleFormChangeNumbers = (event) => {
    const { name, value } = event.target;
    if (isNaN(Number(value))) return; //only accept numbers
    onUpdateState(name, value);
  };

  const onCvvFocus = () => {
    setIsCardFlipped(true);
  };

  const onCvvBlur = () => {
    setIsCardFlipped(false);
  };

  const handleConfirmAction = (e) => {
    // validate errors
    if (!isFormHasErrors()) {
      handleSubmitAction();
    }
  };
  const isFormHasErrors = () => {
    const newErrors = {
      id: '',
      cardNumber: '',
      cardHolder: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
    };
    //first validate blank fields
    let isErrorFlag = false;
    // Object.keys(newErrors).forEach(function (key) {
    //   const keyPair = key as keyof CreditCard;
    //   const displayableKeyName = key.toLowerCase().replace('card', 'Card ');
    //   if (!selectedCreditCard[keyPair]) {
    //     newErrors[keyPair] = `${displayableKeyName} value required.`;
    //     isErrorFlag = true;
    //   } else {
    //     newErrors[keyPair] = '';
    //     isErrorFlag = false;
    //   }
    // });
    if (isErrorFlag) {
      setErrors(newErrors);
      return isErrorFlag;
    }
    //if no blank field then check other validation
    if (selectedCreditCard['cardNumber'].length !== 16) {
      newErrors.cardNumber = 'Card number should be 16 digits';
      isErrorFlag = true;
    }
    if (selectedCreditCard['cardCvv'].length !== 4) {
      newErrors.cardCvv = 'Card number should be 4 digits';
      isErrorFlag = true;
    }
    setErrors(newErrors);
    return isErrorFlag;
  };

  const handleCardPayment = (event) => {
    event.preventDefault();
    dispatch(
      MakePayment({
        type: 'card',
        currency: default_currency_details.hasOwnProperty('iso')
          ? default_currency_details.iso
          : 'USD',
        amount: total,
        phone: client_details.phonenumber.split(' ')[1],
        order_id: draft_id.toString(),
        network: '',
        user: {
          id: client_details.id,
          first_name: client_details.firstname,
          last_name: client_details.lastname,
          email: client_details.email,
          address: '',
          country: default_currency_details.hasOwnProperty('country_code')
            ? default_currency_details.country_code
            : client_details.country,
          city: client_details.city,
          zip: client_details.postcode,
          state: client_details.state,
        },
        cardInfo: [
          {
            cardno: selectedCreditCard.cardNumber,
            cvv: selectedCreditCard.cardCvv,
            expirymonth: selectedCreditCard.cardMonth,
            expiryyear: selectedCreditCard.cardYear,
          },
        ],
      })
    );
  };

  const handleBankPayment = (event) => {
    event.preventDefault();
    dispatch(
      MakePayment({
        type: 'bank_transfer',
        currency: default_currency_details.hasOwnProperty('iso')
          ? default_currency_details.iso
          : 'USD',
        amount: total,
        phone: client_details.phonenumber.split(' ')[1],
        order_id: draft_id.toString(),
        network: '',
        user: {
          id: '1',
          first_name: 'Jean Francois',
          last_name: 'Munyaneza',
          email: 'jeanfrancoismunyaneza@gmail.com',
          adrress: '',
          country: 'rw',
          city: '',
          zip: '',
          state: '',
        },
        cardInfo: {},
      })
    );
  };

  const handleMOMOPayment = (event) => {
    event.preventDefault();

    setErrMessage('');
    setPaymentLoader(!paymentLoader);
    let p_type = '';
    if (default_currency_details.country === 'Rwanda') {
      p_type = 'mobile_money_rwanda';
    } else if (default_currency_details.country === 'Zambia') {
      p_type = 'mobile_money_zambia';
    } else if (default_currency_details.country === 'Uganda') {
      p_type = 'mobile_money_uganda';
    } else if (default_currency_details.country === 'Ghana') {
      p_type = 'mobile_money_ghana';
    } else if (default_currency_details.country === 'Tanzania') {
      p_type = 'mobile_money_tanzania';
    } else {
      if (
        default_currency_details.country === 'Ivory Coast' ||
        default_currency_details.country === 'Senegal' ||
        default_currency_details.country === 'Cameroon' ||
        default_currency_details.country === 'Mali' ||
        default_currency_details.country === 'Burkina Faso' ||
        default_currency_details.country === 'Togo' ||
        default_currency_details.country === 'Congo' ||
        default_currency_details.country === 'Guinea' ||
        default_currency_details.country === 'Benin'
      ) {
        p_type = 'mobile_money_franco';
      }
    }
    dispatch(
      MakePayment({
        type: p_type,
        currency: default_currency_details.hasOwnProperty('iso')
          ? default_currency_details.iso
          : 'USD',
        amount: total,
        phone: phoneNumber,
        order_id: draft_id.toString(),
        network: '',
        user: {
          id: client_details.id,
          first_name: client_details.firstname,
          last_name: client_details.lastname,
          email: client_details.email,
          address: '',
          country: default_currency_details.hasOwnProperty('country_code')
            ? default_currency_details.country_code
            : client_details.country,
          city: client_details.city,
          zip: client_details.postcode,
          state: client_details.state,
        },
        cardInfo: {},
      })
    );
  };

  const handlePaymentStatus = (url) => {
    dispatch(CheckPaymentStatus(url));
  };

  useEffect(() => {
    if (
      payment_response.hasOwnProperty('check_url') === true &&
      payment_status.hasOwnProperty('status') === false
    ) {
      handlePaymentStatus(payment_response.check_url);
      if (payment_response.hasOwnProperty('payment_url') === true) {
        window.open(`${payment_response.payment_url}`, '_blank');
      }
    }

    if (payment_status.hasOwnProperty('status')) {
      if (payment_status.status === 'pending' && checkStatus === true) {
        window.setTimeout(() => {
          handlePaymentStatus(payment_response.check_url);
          counter++;
        }, 10000);
        if (
          counter === 12 &&
          payment_response.hasOwnProperty('payment_url') === true
        ) {
          setPaymentLoader(false);
          setErrMessage('failed');
          setCheckStatus(false);
          counter = 0;
        }
        if (
          counter === 6 &&
          payment_response.hasOwnProperty('payment_url') === false
        ) {
          setPaymentLoader(false);
          setErrMessage('failed');
          setCheckStatus(false);
          counter = 0;
        }
      } else if (payment_status.status === 'success') {
        setPaymentLoader(false);
        ls.set('hspyd', true, { encrypt: true });
        navigate('/setup');
      } else {
        if (payment_status.status === 'fail') {
          setPaymentLoader(false);
          setErrMessage('failed');
          setCheckStatus(false);
          counter = 0;
        }
        if (
          payment_status.status === 'fail' &&
          payment_status.message === 'Insufficient Fund'
        ) {
          setPaymentLoader(false);
          setErrMessage('failed');
          setCheckStatus(false);
          counter = 0;
        }
      }
    }
  }, [payment_response, payment_status]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      setOpen(!open);
    }
  }, [errMessage, open]);

  useEffect(() => {
    if (client_details.hasOwnProperty('id')) {
      // Set customer info
      setFlutterwaveData({
        ...flutterwaveData,
        customer: {
          email: client_details.email,
          name: `${client_details.firstname} ${client_details.lastname}`,
          phonenumber: client_details.phonenumber.split(' ')[1],
        },
        country: default_currency_details.hasOwnProperty('country_code')
          ? default_currency_details.country_code
          : client_details.country,
      });
    }
  }, [client_details]);
  const handleFlutterPayment = useFlutterwave(flutterwaveData);

  const handlePayment = (payment_res) => {
    if (payment_res.status === 'successful') {
      ls.set('hspyd', true, { encrypt: true });
      navigate('/setup');
    }
  };

  return (
    <>
      {/* {errMessage === 'failed' && (
        <Snackbar
          open={!open}
          autoHideDuration={4000}
          onClose={() => setOpen(!open)}
        >
          <Alert
            onClose={() => setOpen(!open)}
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
          >
            Something went wrong please try again!
          </Alert>
        </Snackbar>
      )} */}
      {/* {errMessage === 'insufficient_balance' && (
        <Snackbar
          open={!open}
          autoHideDuration={4000}
          onClose={() => setOpen(!open)}
        >
          <Alert
            onClose={() => setOpen(!open)}
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
          >
            Your payment could not be completed. Reason: Insufficient balance
          </Alert>
        </Snackbar>
      )} */}
      <section className="section">
        <div className="container mt-9">
          <div className="row">
            <div className="col-md-5 m-auto ">
              <div className={classes.orderSummary}>
                <div className={classes.center}>
                  {total <= 0 && (
                    <CircularProgress className={classes._loaderStyle} />
                  )}
                </div>
                <div className={total <= 0 ? classes.cardMask : ''}>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <h1 className="display-3 mb-0 text-center">Pay with</h1>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 m-auto">
                          <ul
                            className="nav nav-pills nav-fill flex-column flex-md-row"
                            id="tabs-icons-text"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <Link
                                onClick={() => {
                                  setPaymentOpt('mobile-money');
                                }}
                                className={`nav-link border rounded-pill ${
                                  paymentOpt === 'mobile-money' ? 'active' : ''
                                }`}
                                data-toggle="tab"
                                to=""
                                role="tab"
                                aria-controls="plans"
                                aria-selected="true"
                              >
                                <p className="m-0 font-weight-bold">
                                  Mobile Money
                                </p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                onClick={() => {
                                  setPaymentOpt('card');
                                }}
                                className={`nav-link border rounded-pill  ${
                                  paymentOpt === 'card' ? 'active' : ''
                                }`}
                                data-toggle="tab"
                                to=""
                                role="tab"
                                aria-controls="domains"
                                aria-selected="false"
                              >
                                <p className="m-0 font-weight-bold">
                                  Pay with Card
                                </p>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`row ${
                          paymentOpt === 'bank' || paymentOpt === 'mobile-money'
                            ? 'mt-2'
                            : 'mt-5'
                        } mb-5`}
                      >
                        {/* {paymentOpt === 'card' && (
                          <div className="col-md-12 c-card-holder">
                            {children}
                          </div>
                        )} */}
                        <div className="col-md-12 text-center">
                          {paymentOpt === 'card' && (
                            <>
                              <h4 className="font-weight-bold mb-5">
                                You are about to pay{' '}
                                <span className="text-success">
                                  {`${new Intl.NumberFormat('en-US').format(
                                    total
                                  )} ${default_currency_details.iso}`}
                                </span>
                              </h4>
                              <button
                                type="button"
                                onClick={() => {
                                  setPaymentLoader(!paymentLoader);
                                  handleFlutterPayment({
                                    callback: (response) => {
                                      handlePayment(response);
                                      setPaymentLoader(false);
                                      closePaymentModal(); // this will close the modal programmatically
                                    },
                                    onClose: () => {},
                                  });
                                }}
                                className="col-md-12 btn btn-lg btn-primary btn-round pl-5 pr-5"
                                disabled={paymentLoader ? true : false}
                              >
                                {paymentLoader ? (
                                  <CircularProgress
                                    className={classes.loaderStyle}
                                  />
                                ) : (
                                  'Continue'
                                )}
                              </button>
                            </>

                            // <form onSubmit={handleCardPayment}>
                            //   <div className="row mt-5">
                            //     <div className="col-md-12 mt-3">
                            //       <div className="form-group">
                            //         <div className="input-group">
                            //           <div className="input-group-prepend">
                            //             <span className="input-group-text">
                            //               <i className="ni ni-credit-card"></i>
                            //             </span>
                            //           </div>
                            //           <input
                            //             className="form-control form-control-lg"
                            //             placeholder="Card number"
                            //             type="text"
                            //             name="cardNumber"
                            //             autoComplete="off"
                            //             onChange={handleFormChangeNumbers}
                            //             maxLength={16}
                            //             value={selectedCreditCard.cardNumber}
                            //             required
                            //           />
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <div className="col-md-12 mt-3">
                            //       <div className="form-group">
                            //         <div className="input-group">
                            //           <div className="input-group-prepend">
                            //             <span className="input-group-text">
                            //               <i className="ni ni-single-02"></i>
                            //             </span>
                            //           </div>
                            //           <input
                            //             className="form-control form-control-lg"
                            //             placeholder="Card holder name"
                            //             type="text"
                            //             autoComplete="off"
                            //             name="cardHolder"
                            //             onChange={handleFormChange}
                            //             value={selectedCreditCard.cardHolder}
                            //             required
                            //           />
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <div className="col-md-4 mt-3">
                            //       <div className="form-group">
                            //         <div className="input-group">
                            //           <div className="input-group-prepend">
                            //             <span className="input-group-text">
                            //               <i className="ni ni-calendar-grid-58"></i>
                            //             </span>
                            //           </div>
                            //           <select
                            //             className="form-control form-control-lg"
                            //             data-trigger=""
                            //             name="cardMonth"
                            //             id="choices-single-default"
                            //             value={selectedCreditCard.cardMonth}
                            //             onChange={handleFormChange}
                            //             required
                            //           >
                            //             <option value="" disabled>
                            //               Month
                            //             </option>

                            //             {monthsArr.map((val, index) => (
                            //               <option key={index} value={val}>
                            //                 {val}
                            //               </option>
                            //             ))}
                            //           </select>
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <div className="col-md-4 mt-3">
                            //       <div className="form-group">
                            //         <div className="input-group">
                            //           <div className="input-group-prepend">
                            //             <span className="input-group-text">
                            //               <i className="ni ni-calendar-grid-58"></i>
                            //             </span>
                            //           </div>
                            //           <select
                            //             className="form-control form-control-lg"
                            //             data-trigger=""
                            //             name="cardYear"
                            //             id="choices-single-default"
                            //             value={selectedCreditCard.cardYear}
                            //             onChange={handleFormChange}
                            //             required
                            //           >
                            //             <option value="" disabled>
                            //               Year
                            //             </option>

                            //             {yearsArr.map((val, index) => (
                            //               <option key={index} value={val}>
                            //                 {val}
                            //               </option>
                            //             ))}
                            //           </select>
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <div className="col-md-4 mt-3">
                            //       <div className="form-group">
                            //         <div className="input-group">
                            //           <div className="input-group-prepend">
                            //             <span className="input-group-text">
                            //               <i className="ni ni-key-25"></i>
                            //             </span>
                            //           </div>
                            //           <input
                            //             className="form-control form-control-lg"
                            //             placeholder="CVV"
                            //             type="text"
                            //             maxLength={4}
                            //             autoComplete="off"
                            //             name="cardCvv"
                            //             value={selectedCreditCard.cardCvv}
                            //             onChange={handleFormChangeNumbers}
                            //             onFocus={onCvvFocus}
                            //             onBlur={onCvvBlur}
                            //             required
                            //           />
                            //         </div>
                            //       </div>
                            //     </div>
                            //     <br />
                            //     <div className="col-md-12 pt-4 text-center m-auto">
                            //       <button
                            //         type="submit"
                            //         className="col-md-12 btn btn-lg btn-primary btn-round pl-5 pr-5"
                            //         disabled={paymentLoader ? true : false}
                            //       >
                            //         {paymentLoader ? (
                            //           <CircularProgress
                            //             className={classes.loaderStyle}
                            //           />
                            //         ) : (
                            //           `Pay ${total} ${default_currency_details.iso}`
                            //         )}
                            //       </button>
                            //     </div>
                            //   </div>
                            // </form>
                          )}
                          {paymentOpt === 'mobile-money' && (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                setModal(true);
                                //handleMOMOPayment();
                              }}
                            >
                              <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                                Enter your payment details
                              </h5>
                              <div className="row mt-2">
                                <div className="col-md-12 mt-3">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-mobile-button"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Phone Number"
                                        type="text"
                                        name="phone_number"
                                        autoComplete="off"
                                        onChange={(event) => {
                                          setPhoneNumber(event.target.value);
                                        }}
                                        maxLength={16}
                                        value={phoneNumber}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div className="col-md-12 pt-4 text-center m-auto">
                                  <button
                                    type="submit"
                                    className="col-md-12 btn btn-lg btn-primary btn-round pl-5 pr-5"
                                    disabled={paymentLoader ? true : false}
                                  >
                                    {paymentLoader ? (
                                      <CircularProgress
                                        className={classes.loaderStyle}
                                      />
                                    ) : (
                                      `Pay ${new Intl.NumberFormat(
                                        'en-US'
                                      ).format(total)} ${
                                        default_currency_details.iso
                                      }`
                                    )}
                                  </button>
                                </div>
                                {paymentLoader && (
                                  <div className="col-md-12 pt-4 text-left m-auto">
                                    <div class="alert alert-info" role="alert">
                                      <i
                                        class="fa fa-info-circle mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Please check you phone to complete this
                                      transaction!
                                    </div>
                                  </div>
                                )}
                                {errMessage === 'failed' && (
                                  <div className="col-md-12 pt-4 text-left m-auto">
                                    <div
                                      class="alert alert-danger"
                                      role="alert"
                                    >
                                      <i
                                        class="fa fa-info-circle mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Something went wrong please try again!
                                    </div>
                                  </div>
                                )}
                              </div>
                            </form>
                          )}
                          {paymentOpt === 'bank' && (
                            <form onSubmit={handleBankPayment}>
                              <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                                Enter your payment details
                              </h5>
                              <div className="row mt-2">
                                <div className="col-md-6 mt-3">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-pin-3"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Address Line"
                                        type="text"
                                        name="address_line"
                                        autoComplete="off"
                                        onChange={(event) => {
                                          setAddressLine(event.target.value);
                                        }}
                                        maxLength={16}
                                        value={addressLine}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-pin-3"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="City"
                                        type="text"
                                        name="city"
                                        autoComplete="off"
                                        onChange={(event) => {
                                          setCity(event.target.value);
                                        }}
                                        maxLength={16}
                                        value={city}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-pin-3"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Postcode Area"
                                        type="text"
                                        name="postcode_area"
                                        autoComplete="off"
                                        onChange={(event) => {
                                          setPostcodeArea(event.target.value);
                                        }}
                                        maxLength={16}
                                        value={postcodeArea}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="ni ni-pin-3"></i>
                                        </span>
                                      </div>
                                      <input
                                        className="form-control form-control-lg"
                                        placeholder="Postal Code"
                                        type="text"
                                        name="postal_code"
                                        autoComplete="off"
                                        onChange={(event) => {
                                          setPostalCode(event.target.value);
                                        }}
                                        maxLength={16}
                                        value={postalCode}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div className="col-md-12 pt-4 text-center m-auto">
                                  <button
                                    type="submit"
                                    className="col-md-12 btn btn-lg btn-primary btn-round pl-5 pr-5"
                                    disabled={paymentLoader ? true : false}
                                  >
                                    {paymentLoader ? (
                                      <CircularProgress
                                        className={classes.loaderStyle}
                                      />
                                    ) : (
                                      `Pay ${new Intl.NumberFormat(
                                        'en-US'
                                      ).format(total)} ${
                                        default_currency_details.iso
                                      }`
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                        {/* <div className="payment-option col-md-12 text-left mt-5">
                      <h6>Payment options</h6>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                            value={'card'}
                            onChange={handlePaymentOpt}
                            checked={paymentOpt === 'card'}
                          />
                          Card
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                            value={'bank'}
                            onChange={handlePaymentOpt}
                            checked={paymentOpt === 'bank'}
                          />
                          Bank
                        </label>
                      </div>
                      <div className="form-check disabled">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                            value={'mobile-money'}
                            onChange={handlePaymentOpt}
                            checked={paymentOpt === 'mobile-money'}
                          />
                          Mobile money
                        </label>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          size="md"
          toggle={toggle}
          centered
          modalTransition={{ timeout: 300 }}
        >
          <ModalBody>
            <div className="row">
              <div className="col-md-12 m-auto py-4 px-4 text-center">
                <>
                  <div>
                    <BsExclamationCircleFill color="#f7b113" size="55" />
                    <h1 className="display-4 text-centerm my-3">
                      Payment Info
                    </h1>
                    <span>
                      Please make sure you have sufficient balance in your
                      Mobile Money account before proceeding. If your balance is
                      not enough click Cancel.
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={(event) => {
                      handleMOMOPayment(event);
                      setModal(!modal);
                    }}
                    className="btn btn-md btn-primary btn-round pl-5 pr-5 mt-4"
                  >
                    I have the balance <BsArrowRight size="20" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(!modal);
                    }}
                    className="btn btn-md btn-danger-outline btn-round pl-5 pr-5 mt-4"
                  >
                    Cancel
                  </button>
                </>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </section>
    </>
  );
}

import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  client: {},
  client_details: {},
  login_url: '',
  message: '',
  isAuthenticated: false,
  email_verification: {},
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.success,
        client: action.payload,
        message: action.payload.success === true ? 'registered' : 'failed',
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.success,
        client: action.payload,
        message: action.payload.success === true ? 'logged_in' : 'failed',
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        client: {},
        message: '',
      };
    case types.RESET_PWD_SUCCESS:
      return {
        ...state,
        message: 'email sent',
      };
    case types.UPDATE_PWD_SUCCESS:
      return {
        ...state,
        message: 'updated',
      };
    case types.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        message: 'account verified',
      };
    case types.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        email_verification: action.payload,
      };

    case types.SEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        message: 'verification sent',
      };

    case types.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        message: 'token match',
      };
    case types.GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        client_details: action.payload.client,
        login_url: action.payload.login_url,
      };
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};

export default auth;

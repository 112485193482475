import React from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';

export const Support = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card card-project" style={{ minHeight: 'auto' }}>
                <Link to="">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                    <i className="ni ni-send"></i>
                  </div>
                </Link>
                <div className="card-body ">
                  <h4 className="card-title text-center mt-3">
                    Support tickets
                  </h4>
                  {/* <!-- <p className="card-description">Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.</p> --> */}
                  <div className="card-footer">
                    <Link to="" className="btn btn-link text-secondary">
                      <i className="ni ni-send"></i> Open a ticket
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card card-project" style={{ minHeight: 'auto' }}>
                <Link to="">
                  <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto">
                    <i className="ni ni-chat-round"></i>
                  </div>
                </Link>
                <div className="card-body ">
                  <h4 className="card-title text-center mt-3">
                    Start a live Chat
                  </h4>
                  {/* <!-- <p className="card-description">Pink is obviously a better color. Everyone’s born confident, and everything’s taken away from you matters is the people who are sparked by it follow their dreams, too.</p> --> */}
                  <div className="card-footer">
                    <Link to="" className="btn btn-link text-danger">
                      <i className="ni ni-chat-round"></i> Live chat
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card card-project" style={{ minHeight: 'auto' }}>
                <Link to="">
                  <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto">
                    <i className="fa fa-phone"></i>
                  </div>
                </Link>
                <div className="card-body ">
                  <h4 className="card-title text-center mt-3">Call Us</h4>
                  {/* <p className="card-description">Constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams the position that we want to be.</p> */}
                  <div className="card-footer">
                    <Link to="" className="btn btn-link text-info">
                      <i className="fa fa-phone"></i> Call Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

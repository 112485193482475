import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { PageLoader } from '../components/PageLoader';
import AddCard from '../CardManager/AddCard/AddCard';

export const Checkout = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <AddCard />
    </>
  );
};

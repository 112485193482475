import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { CompleteOrderSection } from '../components/CompleteOrderSection';
import { PageLoader } from '../components/PageLoader';

export const CompleteOrder = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <CompleteOrderSection />
    </>
  );
};

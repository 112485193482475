import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { useSelector, useDispatch } from 'react-redux';
import { GetCurrencies, SetDefaultCurrency } from '../redux/actions/currency';

export const PageLoader = () => {
  const [wasCalled, setWasCalled] = useState(false);
  const dispatch = useDispatch();
  const { currencies } = useSelector((state) => state.currency);

  useEffect(() => {
    let defaultExists = ls.get('default_currency', { decrypt: true });
    if (defaultExists === null) {
      dispatch(GetCurrencies());
    }
  }, []);

  useEffect(() => {
    if (
      currencies.currencies !== undefined &&
      currencies.currencies.length > 0 &&
      currencies.hasOwnProperty('clientIp')
    ) {
      const api_key_1 = 'Bearer 2275|hvAdJ1mzjEhAefs7q90MJ79Q6gTUSPth5ls8mQpO';
      const api_key_2 = 'Bearer 2285|EUBTAt5WJVOajM6P7WQJUJ9BZeowbOd26bOxpbS8';
      let chosenCurrency = ls.get('default_currency', { decrypt: true });
      let counter = 0;
      const settings = {
        async: true,
        crossDomain: true,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: api_key_1,
          'cache-control': 'no-cache',
        },
        processData: false,
      };

      if (chosenCurrency === null && wasCalled === false) {
        counter++;
        console.log('counter:', wasCalled);
        setWasCalled(true);
        fetch(`https://ipxapi.com/api/ip?ip=${currencies.clientIp}`, settings)
          .then(function (response) {
            return response.json();
          })
          .then(function (payload) {
            setWasCalled(true);
            currencies.currencies !== undefined &&
              currencies.currencies.length > 0 &&
              currencies.currencies
                .filter((currency) => {
                  return currency.country_code === payload.countryCode;
                })
                .map((filteredCurrency) => {
                  if (filteredCurrency.hasOwnProperty('code')) {
                    dispatch(
                      SetDefaultCurrency({
                        ...filteredCurrency,
                        city: payload.city,
                      })
                    );
                  } else {
                    let newCurrency = {
                      id: 1,
                      code: 'USD',
                      country_code: payload.countryCode,
                      sign: '$',
                      iso: 'USD',
                      rate: '0.002000000',
                      last_changes: '2022-02-05 14:52:17',
                      update: '1',
                      enable: '1',
                      country: payload.country,
                      city: payload.city,
                      format: '1,234.56',
                      created_at: '2022-03-04T14:07:18.000000Z',
                      updated_at: '2022-03-04T14:07:18.000000Z',
                    };
                    dispatch(SetDefaultCurrency(newCurrency));
                  }
                });
          });
      } else {
        dispatch(SetDefaultCurrency(chosenCurrency));
      }
    }
  }, [currencies]);
  return <></>;
};

import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { PageLoader } from '../components/PageLoader';
import { TermsSection } from '../components/TermsSection';
import { Footer } from '../components/Footer';

export const Terms = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <TermsSection />
      <Footer />
    </>
  );
};

import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { SearchDomainSection } from '../components/SearchDomainSection';
import { PageLoader } from '../components/PageLoader';

export const SearchDomain = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <SearchDomainSection />
    </>
  );
};

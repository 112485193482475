import React from 'react';
import ls from 'localstorage-slim';

export const GetStarted = () => {
  return (
    <>
      <section id="get_started" className="section mt-0">
        <div className="overlay"></div>
        <div className="container-fluid mt-5 higher-index-2">
          <div className="row">
            <div className="col-md-12 mb-4 text-center">
              <h1 className="display-1 d-none d-md-block text-white">
                How to Get Started Cloudhost Today
              </h1>
              <h1 className="mt-6 d-md-none d-block text-white">
                {' '}
                How to Get Started Cloudhost Today
              </h1>
              <h2 className="text-white d-none d-md-block">
                Get a Free Domain name for Life
              </h2>
              <h4 className="text-white d-md-none d-block">
                Get a Free Domain name for Life
              </h4>
              {/* <p className="pb-4 text-white mt-0">We provide reliable, fast and secure Web hosting services with Premium Customer service of over 15+ years of experience.</p> */}
            </div>

            <div className="col-md-6 m-auto">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-header bg-transparent">
                      <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                        Free domain transfer
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="text-muted text-center">
                        Transfer to Clouldhost today, Our free domain transfer
                        will cover the full transfer cost for any
                        <strong className="font-weight-bold">.cm</strong>,{' '}
                        <strong className="font-weight-bold">.rw</strong>,
                        <strong className="font-weight-bold">.com</strong>,{' '}
                        <strong className="font-weight-bold">.net</strong>{' '}
                        domains when you purchase one of our shared hosting
                        plans. If you cancel your hosting account in the first
                        term a non-refundable domain fee will be applied.
                      </p>
                      <button className="btn btn-primary btn-round btn-lg w-100 mt-2">
                        Transfer Now
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-header bg-transparent">
                      <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                        Free website migration
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="text-muted text-center">
                        Our team understands that changing hosting providers can
                        be a confusing and daunting task, That's why our
                        experienced migration team handles everything for you.
                        Types of websites we migrate includes:{' '}
                        <strong className="font-weight-bold">Wordpress</strong>,
                        <strong className="font-weight-bold">Joomla</strong>,{' '}
                        <strong className="font-weight-bold">Drupal</strong>,
                        <strong className="font-weight-bold">
                          Custom HTML
                        </strong>
                        .
                      </p>
                      <button className="btn btn-primary btn-round btn-lg w-100 mt-3">
                        Transfer Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

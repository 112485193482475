import axios from 'axios';

// Development
//const instance = axios.create({ baseURL: 'http://localhost:8000' });

// Production
const instance = axios.create({
  baseURL: 'https://apihostbill.globexcamhost.com',
});

export default instance;

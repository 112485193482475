import React from 'react';
import { HomeHeader } from '../components/HomeHeader';
import { Plans } from '../components/Plans';
import { FeaturesIncluded } from '../components/FeaturesIncluded';
import { VP } from '../components/VP';
import { FAQ } from '../components/FAQ';
import { GetStarted } from '../components/GetStarted';
import { Support } from '../components/Support';
import { BPlans } from '../components/BPlans';
import { Footer } from '../components/Footer';
import { PageLoader } from '../components/PageLoader';

export const Home = () => {
  return (
    <>
      <PageLoader />
      <HomeHeader />
      <Plans />
      <FeaturesIncluded />
      <VP />
      <FAQ />
      <GetStarted />
      <Support />
      {/* <BPlans /> */}
      <Footer />
    </>
  );
};

import React from 'react';
import { AllFeaturesHeader } from '../components/AllFeaturesHeader';
import { AllFeaturesIncluded } from '../components/AllFeaturesIncluded';
import { ComparePlans } from '../components/ComparePlans';
import { FAQ } from '../components/FAQ';
import { GetStarted } from '../components/GetStarted';
import { Support } from '../components/Support';
import { BPlans } from '../components/BPlans';
import { Footer } from '../components/Footer';
import { PageLoader } from '../components/PageLoader';

export const AllFeatures = () => {
  return (
    <>
      <PageLoader />
      <AllFeaturesHeader />
      <ComparePlans />
      <AllFeaturesIncluded />
      <FAQ />
      <GetStarted />
      <Support />
      <BPlans />
      <Footer />
    </>
  );
};

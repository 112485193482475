import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  order: {},
  draft_id: '',
  order_message: '',
  payment_response: {},
  payment_status: {},
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        order_message: '',
      };
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
      };
    case types.CREATE_ORDER_DRAFT_SUCCESS:
      ls.set('order_draft', action.payload, { encrypt: true });
      return {
        ...state,
        order_draft: action.payload,
      };
    case types.ADD_ORDER_DRAFT_ITEM_SUCCESS:
      return {
        ...state,
        order_message: 'added',
      };
    case types.EDIT_ORDER_DRAFT_SUCCESS:
      return {
        ...state,
        order: action.payload,
        order_message: 'edited',
      };
    case types.CONVERT_ORDER_DRAFT_SUCCESS:
      return {
        ...state,
        order_message: 'converted',
      };
    case types.REMOVE_ORDER_DRAFT_ITEM_SUCCESS:
      return {
        ...state,
        order_message: 'removed',
      };
    case types.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment_response: action.payload,
      };
    case types.CHECK_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        payment_status: action.payload,
      };

    default:
      return state;
  }
};

export default order;

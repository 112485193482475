import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  error: '',
  error_msg: '',
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        error_msg: action.payload.error_msg,
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: '',
        error_msg: '',
      };
    default:
      return state;
  }
};

export default errors;

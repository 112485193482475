import React from 'react';
import { PageLoader } from '../components/PageLoader';
import { VerifyToken } from '../components/VerifyToken';

export const Verify = () => {
  return (
    <>
      <PageLoader />
      <VerifyToken />
    </>
  );
};

import React from 'react';
import { WhiteHeader } from '../components/WhiteHeader';
import { OrderSection } from '../components/OrderSection';
import { PageLoader } from '../components/PageLoader';

export const Order = () => {
  return (
    <>
      <PageLoader />
      <WhiteHeader />
      <OrderSection />
    </>
  );
};

import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  domain: [],
  domain_period: 1,
  domain_message: '',
  domain_details: {},
};

const domain = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case types.CHECK_DOMAIN_SUCCESS:
      return {
        ...state,
        domain: action.payload,
        domain_message: '',
      };
    case types.REGISTER_DOMAIN_SUCCESS:
      return {
        ...state,
        domain_message: 'domain registered',
      };
    case types.SEND_DNS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        domain_message: 'notification sent',
      };
    case types.SET_DOMAIN_DETAILS_SUCCESS:
      ls.set('domain_details', action.payload, { encrypt: true });
      return {
        ...state,
        domain_details: action.payload,
      };
    default:
      return state;
  }
};

export default domain;

import { types } from '../actions/types';
import ls from 'localstorage-slim';

const initialState = {
  isLoading: false,
  _isLoading: false,
};

const loader = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case types.CLOSE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case types.OPEN_INNER_LOADER:
      return {
        ...state,
        _isLoading: true,
      };
    case types.CLOSE_INNER_LOADER:
      return {
        ...state,
        _isLoading: false,
      };
    default:
      return state;
  }
};

export default loader;

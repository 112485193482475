import { types } from './types';
import ls from 'localstorage-slim';

export const setErrors = (error) => {
  return {
    type: types.SET_ERROR,
    payload: error,
  };
};

export const clearErrors = () => {
  return {
    type: types.CLEAR_ERROR,
  };
};

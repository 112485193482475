import React, { useEffect, useRef, useState } from 'react';
import ls from 'localstorage-slim';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clearErrors } from '../redux/actions/errors';
import { BsArrowRight, BsFillCloudCheckFill } from 'react-icons/bs';
import { ConvertOrderDraft, GetOrderDraft } from '../redux/actions/order';
import { RegisterDomain, SendDnsNotification } from '../redux/actions/domain';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loaderStyle: {
    width: '30px !important',
    height: '30px !important',
    color: '#4580F9 !important',
    margin: '0px auto !important',
  },
  mTop: {
    marginTop: '15%',
  },
  Center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '5px solid #FFFF00',
    padding: '10px',
  },
}));

export const AccountSetup = () => {
  const local_exts = ['.rw', '.co.rw', '.org.rw', '.ac.rw', '.net.rw'];
  let canActivate = useRef(false);
  let hasDomain = useRef(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.auth);
  const { client_details, login_url } = useSelector((state) => state.auth);
  const { order_message } = useSelector((state) => state.order);
  const { domain_message } = useSelector((state) => state.domain);

  const [currentPeriod, setCurrentPeriod] = useState(1);
  const [usePreInfo, setUserPreInfo] = useState(null);

  let chosen_ext = ls.get('chosen_ext', { decrypt: true });

  useEffect(() => {
    let has_domain = ls.get('has_domain', { decrypt: true });
    hasDomain.current = has_domain;
  }, []);
  useEffect(() => {
    if (
      chosen_ext &&
      chosen_ext !== null &&
      chosen_ext !== undefined &&
      chosen_ext !== ''
    ) {
      if (local_exts.includes(chosen_ext) !== true) {
        canActivate.current = true;
      }
    }
    if (hasDomain.current === true) {
      canActivate.current = true;
    }
  }, []);

  useEffect(() => {
    let has_paid = ls.get('hspyd', { decrypt: true });
    if ((has_paid === null || has_paid === undefined) && has_paid !== true) {
      navigate('/');
      return;
    }

    let contact_data = {};
    let domain_data = {};
    let draft_id = ls.get('order_draft', { decrypt: true });

    let current_period = ls.get('current_period', { decrypt: true });
    let chosen_domain = ls.get('chosen_domain', { decrypt: true });
    let UserPreInfo = ls.get('UserPreInfo', { decrypt: true });

    setUserPreInfo(UserPreInfo);
    if (
      current_period &&
      current_period !== null &&
      current_period !== undefined
    ) {
      setCurrentPeriod(current_period);
    }

    if (client_details.hasOwnProperty('id')) {
      contact_data[
        'name'
      ] = `${client_details.firstname} ${client_details.lastname}`;
      contact_data['email'] = client_details.email;
      contact_data['street'] = client_details.city;
      contact_data['city'] = client_details.city;
      contact_data['province'] = client_details.city;
      contact_data['postcode'] = client_details.postcode;
      contact_data['country_code'] = client_details.country;
      contact_data['voice'] = client_details.phonenumber.split(' ')[1];
      contact_data['organization'] = 'private';
      contact_data['password'] = 'User@123!';

      domain_data['period'] = parseInt(currentPeriod);
      domain_data['ns1'] = 'ns1.globexcamhost.com';
      domain_data['ns2'] = 'ns2.globexcamhost.com';
      domain_data['domain_name'] = chosen_domain;
      domain_data['password'] = 'User@123!';
    }

    let data = {
      contact_data,
      domain_data,
    };
    //console.log({ data });

    // if (local_exts.includes(chosen_ext)) {
    //   dispatch(RegisterDomain(data));
    // } else {
    //   dispatch(
    //     ConvertOrderDraft({
    //       draft_id: parseInt(draft_id),
    //       canActivate,
    //     })
    //   );
    // }
  }, []);

  useEffect(() => {
    let draft_id = ls.get('order_draft', { decrypt: true });
    dispatch(
      ConvertOrderDraft({
        draft_id: parseInt(draft_id),
        canActivate: canActivate.current,
      })
    );
  }, []);

  // useEffect(() => {
  //   let draft_id = ls.get('order_draft'));
  //   if (domain_message === 'domain registered') {
  //     dispatch(
  //       ConvertOrderDraft({
  //         draft_id: parseInt(draft_id),
  //         canActivate,
  //       })
  //     );
  //   }
  // }, [domain_message, canActivate]);

  useEffect(() => {
    if (order_message === 'converted') {
      if (hasDomain === true && usePreInfo !== null) {
        dispatch(SendDnsNotification(usePreInfo));
      }
    }
  }, [order_message, hasDomain, usePreInfo]);

  return (
    <section className="section">
      <div className={`${classes.mTop} container`}>
        <div className="row">
          <div className="col-md-4 m-auto">
            <div className="card">
              <div className="card-body text-center">
                {order_message === 'converted' ? (
                  <>
                    <div>
                      <BsFillCloudCheckFill color="#4580F9" size="60" />
                      <h1 className="display-4 mb-0 text-center">
                        Congratulations!
                      </h1>
                      <span>All is set now!</span>
                    </div>
                    <a
                      href={login_url}
                      onClick={() => {
                        localStorage.clear();
                      }}
                      className="btn btn-md btn-primary btn-round pl-5 pr-5 mt-4"
                    >
                      Go to dashboard <BsArrowRight size="20" />
                    </a>
                  </>
                ) : (
                  <div>
                    <div>
                      <CircularProgress className={classes.loaderStyle} />
                    </div>
                    <div>Setting up your account...</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

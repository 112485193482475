import React from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { BottomCountryBtn } from './BottomCountryBtn';

export const Footer = () => {
  return (
    <>
      <footer className="bg-default text-white footer text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Link className="d-flex my-xl-n3" to="">
                <img
                  src="/assets/images/cloudhost-white.png"
                  className="img-fluid mt-3 w-75"
                  alt="Cloudhost"
                />
              </Link>
              <p className="my-4">
                Our clients, our priority. We offer Web Hosting services with
                premium customer service
              </p>

              <BottomCountryBtn />
            </div>

            <div className="col-md-2">
              <div className="column">
                <h4 className="mt-3 text-light">Services</h4>
                <ul>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      Shared Hosting
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="">Domains names</Link>
                  </li>
                  {/* <!-- <li className="nav-item"><Link target="_blank" to="/vps-hosting.html">VPS Hosting</Link></li> --> */}
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="column">
                <h4 className="mt-3 text-light">Resources</h4>
                <ul>
                  <li className="nav-item">
                    <a href="/policies"> Policy</a>
                  </li>
                  <li className="nav-item">
                    <a href="/terms">Terms of service</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="column">
                <h4 className="mt-3 text-light">Company</h4>
                <ul>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      Why Choose Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="column">
                <h4 className="mt-3 text-light">Contact</h4>
                <ul>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      Phone number: <br /> +250791568908
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link target="_blank" to="">
                      Email Support: <br /> support@cloudhost.cm
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

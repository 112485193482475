import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Home } from './pages/Home';
import { SearchDomain } from './pages/SearchDomain';
import { Order } from './pages/Order';
import { CompleteOrder } from './pages/CompleteOrder';
import { AllFeatures } from './pages/AllFeatures';
import { Verify } from './pages/Verify';
import { AccountSetup } from './components/AccountSetup';
import { BuyDomain } from './pages/BuyDomain';
import { TransferDomain } from './pages/TransferDomain';
// import { Dashboard } from './pages/Dashboard';
// import { MyOrderDetails } from './pages/MyOrderDetails';
// import { MyOrders } from './pages/MyOrders';
// import { MyDomains } from './pages/MyDomains';
// import { Invoices } from './pages/Invoices';
// import { InvoiceDetails } from './pages/InvoiceDetails';
import { Checkout } from './pages/Checkout';
import { Policies } from './pages/Policies';
import { Terms } from './pages/Terms';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/search-domain" element={<SearchDomain />} />
            <Route exact path="/buy-domain" element={<BuyDomain />} />
            <Route exact path="/transfer-domain" element={<TransferDomain />} />
            <Route exact path="/order" element={<Order />} />
            <Route exact path="/complete-order" element={<CompleteOrder />} />
            <Route exact path="/all-features" element={<AllFeatures />} />
            <Route exact path="/verify/:token" element={<Verify />} />
            <Route exact path="/setup" element={<AccountSetup />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/policies" element={<Policies />} />

            {/* <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/my-orders" element={<MyOrders />} />
            <Route exact path="/my-domains" element={<MyDomains />} />
            <Route exact path="/invoices" element={<Invoices />} />
            <Route exact path="/invoice-details" element={<InvoiceDetails />} /> */}
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route
              exact
              path="/my-order-details"
              element={<MyOrderDetails />}
            /> */}
            <Route exact path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

import axios from '../../axios';
import ls from 'localstorage-slim';
import { types } from './types';
import { setErrors, clearErrors } from './errors';

export const clearMessage = () => {
  return {
    type: types.CLEAR_MESSAGE,
  };
};

// Action for fetching currencies
export const GetCurrencies = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  try {
    const response = await axios.get('api/currencies');
    dispatch({
      type: types.GET_CURRENCIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

export const SetDefaultCurrency = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_DEFAULT_CURRENCY_SUCCESS,
    payload: payload,
  });
};

import { types } from './types';
import ls from 'localstorage-slim';

export const openLoader = () => {
  return {
    type: types.OPEN_LOADER,
  };
};

export const closeLoader = () => {
  return {
    type: types.CLOSE_LOADER,
  };
};

export const openInnerLoader = () => {
  return {
    type: types.OPEN_INNER_LOADER,
  };
};

export const closeInnerLoader = () => {
  return {
    type: types.CLOSE_INNER_LOADER,
  };
};

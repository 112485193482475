import React from 'react';

export const MsgText = ({ text, textColor }) => {
  return (
    <>
      <small
        style={
          textColor === 'danger'
            ? {
                fontSize: '12px',
                color: '#ff416c',
                float: 'left',
                margin: '5px 0px 10px 0px',
                display: 'block',
              }
            : {
                fontSize: '12px',
                color: 'green',
                float: 'left',
                margin: '5px 0px 10px 0px',
                display: 'block',
              }
        }
      >
        <i>{text}</i>
      </small>
    </>
  );
};

export const types = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',

  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  OPEN_LOADER: 'OPEN_LOADER',

  CLOSE_LOADER: 'CLOSE_LOADER',

  OPEN_INNER_LOADER: 'OPEN_INNER_LOADER',

  CLOSE_INNER_LOADER: 'CLOSE_INNER_LOADER',

  SET_ERROR: 'SET_ERROR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  CLEAR_MESSAGE: 'CLEAR_MESSAGE',

  RESET_PWD_SUCCESS: 'RESET_PWD_SUCCESS',

  UPDATE_PWD_SUCCESS: 'UPDATE_PWD_SUCCESS',

  VERIFY_ACCOUNT_SUCCESS: 'VERIFY_ACCOUNT_SUCCESS',

  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',

  CHECK_DOMAIN_SUCCESS: 'CHECK_DOMAIN_SUCCESS',

  REGISTER_DOMAIN_SUCCESS: 'REGISTER_DOMAIN_SUCCESS',

  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',

  EDIT_ORDER_DRAFT_SUCCESS: 'EDIT_ORDER_DRAFT_SUCCESS',

  CONVERT_ORDER_DRAFT_SUCCESS: 'CONVERT_ORDER_DRAFT_SUCCESS',

  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',

  SET_DEFAULT_CURRENCY_SUCCESS: 'SET_DEFAULT_CURRENCY_SUCCESS',

  CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',

  CREATE_ORDER_DRAFT_SUCCESS: 'CREATE_ORDER_DRAFT_SUCCESS',

  ADD_ORDER_DRAFT_ITEM_SUCCESS: 'ADD_ORDER_DRAFT_ITEM_SUCCESS',

  REMOVE_ORDER_DRAFT_SUCCESS: 'REMOVE_ORDER_DRAFT_SUCCESS',

  REMOVE_ORDER_DRAFT_ITEM_SUCCESS: 'REMOVE_ORDER_DRAFT_ITEM_SUCCESS',

  SEND_VERIFICATION_SUCCESS: 'SEND_VERIFICATION_SUCCESS',

  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',

  GET_CLIENT_DETAILS_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',

  SEND_DNS_NOTIFICATION_SUCCESS: 'SEND_DNS_NOTIFICATION_SUCCESS',

  SET_DOMAIN_DETAILS_SUCCESS: 'SET_DOMAIN_DETAILS_SUCCESS',

  MAKE_PAYMENT_SUCCESS: 'MAKE_PAYMENT_SUCCESS',
  CHECK_PAYMENT_STATUS_SUCCESS: 'CHECK_PAYMENT_STATUS_SUCCESS',
};

import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetProducts } from '../redux/actions/product';
import { clearErrors } from '../redux/actions/errors';
export const BPlans = () => {
  const dispatch = useDispatch();
  const { products, message } = useSelector((state) => state.product);
  const { default_currency } = useSelector((state) => state.currency);
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  return (
    <>
      <section id="b_plans" className="section mt-0">
        <div className="overlay"></div>
        <div className="container mt-5 higher-index-2">
          <div className="row">
            {products.product !== undefined &&
              products.product.length > 0 &&
              default_currency !== undefined &&
              products.product.map((product) => (
                <div className="col-md-3 col-sm-6" key={product.id}>
                  <div className="card card-pricing bg-white text-center mb-4">
                    <div className="card-header bg-transparent">
                      <h5 className="font-weight-bold text-uppercase ls-1 py-3 mb-0">
                        {product.name}
                      </h5>
                    </div>
                    <div className="card-body">
                      <h6 className="text-muted text-center">
                        <span className="badge badge-pill badge-md badge-success mr-1 text-uppercase">
                          Save 50%
                        </span>
                        <span>First Year</span>
                      </h6>
                      <div className="display-2 d-flex justify-content-center ">
                        {' '}
                        <span className="font-weight-bold ">
                          {new Intl.NumberFormat('en-US').format(
                            product.price *
                              (default_currency.code !== 'FCFA'
                                ? parseFloat(default_currency.rate)
                                : 1)
                          )}
                        </span>{' '}
                        <span
                          className="font-weight-400 pt-3 text-left"
                          style={{ fontSize: '13px' }}
                        >
                          {default_currency.code} <br />
                          Monthly
                        </span>
                      </div>
                      <ul className="list-unstyled my-2">
                        <li className="align-items-center">
                          Renews at{' '}
                          <strong>
                            {new Intl.NumberFormat('en-US').format(
                              product.price *
                                2 *
                                (default_currency.code !== 'FCFA'
                                  ? parseFloat(default_currency.rate)
                                  : 1)
                            )}
                            <sup style={{ fontSize: '16px', lineHeight: '1' }}>
                              {default_currency.code}
                            </sup>
                          </strong>{' '}
                          monthly
                        </li>
                        <Link
                          onClick={() => {
                            ls.set(
                              'chosen_product',
                              products.product.filter(
                                (prod) => prod.id === product.id
                              ),
                              { encrypt: true }
                            );
                          }}
                          to="/search-domain"
                          className="btn btn-primary btn-round btn-lg w-100"
                        >
                          Order Now
                        </Link>
                      </ul>
                    </div>
                    <div className="card-footer text-center bg-transparent">
                      <ul className="list-group simple-list mb-3 d-none d-md-flex">
                        <span
                          className="mb-2 tooltipped text-success"
                          title="Premium Support"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Our friendly and knowledgeable support team is available to help you, just contact support with any issue.</p>"
                        >
                          <i className="fas fa-star "></i> Premium Support{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped  text-success"
                          title="250GB SSD Disk space"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px' style='font-size:'8px''>Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.</p>"
                        >
                          <i className="fa fa-star"></i> 250GB SSD Disk space{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped  text-success"
                          title="5 Websites (addon domains)"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px' style='font-size:'8px''>You can add upto 5 website to your cpanel account.</p>"
                        >
                          <i className="fa fa-star"></i> 5 Websites (addon
                          domains){' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Sub-domains"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limits to the number of sub domains you can add to your website.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Sub-domains{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Mail Accounts"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limits to the number of mail accounts you can have.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Mail Accounts{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Bandwith"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>We don’t limit the amount of visitor traffic a website can receive or the amount of content you can upload to your website so long as you comply with our Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of our servers, we will notify you to take corrective action and may restrict the resources or ask you to choose a plan more suitable to your needs.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Bandwith{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited MySQL Databases"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limit to the number of databases you can create.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          MySQL Databases{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE Domain for life"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Free domains include: <strong className='font-weight-bold'>.cm</strong>, <strong className='font-weight-bold'>.rw</strong>, <strong className='font-weight-bold'>.com</strong>, <strong className='font-weight-bold'>.net</strong>. If you cancel your hosting account in the first term a non-refundable domain fee will be applied.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE
                          Domain for life{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE SSL Certificate"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>We provide you with free SSL certificates through Cloudfare which you can activate from inside your cPanel.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE SSL
                          Certificate{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE cPanel"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Control your website using free Cpanel control panel and special free web hosting admin tools.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE
                          cPanel{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>
                      </ul>

                      {/* For better Accessibility on mobile */}
                      <ul className="list-group simple-list mb-3 d-md-none">
                        <span
                          className="mb-2 text-success"
                          title="Premium Support"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Our friendly and knowledgeable support team is available to help you, just contact support with any issue.</p>"
                        >
                          <i className="fas fa-star "></i> Premium Support{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped text-success"
                          title="250GB SSD Disk space"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px' style='font-size:'8px''>Our storage is available for you to use for your website files only and not for data archiving including email, computer files, backup, etc.</p>"
                        >
                          <i className="fa fa-star"></i> 250GB SSD Disk space{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped  text-success"
                          title="5 Websites (addon domains)"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px' style='font-size:'8px''>You can add upto 5 website to your cpanel account.</p>"
                        >
                          <i className="fa fa-star"></i> 5 Websites (addon
                          domains){' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Sub-domains"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limits to the number of sub domains you can add to your website.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Sub-domains{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Mail Accounts"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limits to the number of mail accounts you can have.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Mail Accounts{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited Bandwith"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>We don’t limit the amount of visitor traffic a website can receive or the amount of content you can upload to your website so long as you comply with our Terms of Service. If your website demands resources that present a risk to the stability, performance, or uptime of our servers, we will notify you to take corrective action and may restrict the resources or ask you to choose a plan more suitable to your needs.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          Bandwith{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="Unlimited MySQL Databases"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>No limit to the number of databases you can create.</p>"
                        >
                          <i className="fa fa-check text-success"></i> Unlimited
                          MySQL Databases{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE Domain for life"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Free domains include: <strong className='font-weight-bold'>.cm</strong>, <strong className='font-weight-bold'>.rw</strong>, <strong className='font-weight-bold'>.com</strong>, <strong className='font-weight-bold'>.net</strong>. If you cancel your hosting account in the first term a non-refundable domain fee will be applied.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE
                          Domain for life{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE SSL Certificate"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>We provide you with free SSL certificates through Cloudfare which you can activate from inside your cPanel.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE SSL
                          Certificate{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>

                        <span
                          className="mb-2 tooltipped"
                          title="FREE cPanel"
                          data-toggle="popover"
                          data-content="<p className='my-0 f-8px'>Control your website using free Cpanel control panel and special free web hosting admin tools.</p>"
                        >
                          <i className="fa fa-check text-success"></i> FREE
                          cPanel{' '}
                          <i className="fas text-light fa-info-circle"></i>
                        </span>
                      </ul>
                      {/* For better Accessibility on mobile */}

                      <hr className="my-1" />
                      <Link
                        to="/all-features"
                        type="button"
                        className="btn btn-link text-primary"
                      >
                        See all Features
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

            <div className="col-md-3 col-sm-6">
              <div className="card card-pricing bg-default border-0 text-center mb-4">
                <div className="card-header bg-transparent">
                  <h5 className="font-weight-bold text-uppercase text-white ls-1 py-3 mb-0">
                    VPS Plans
                  </h5>
                </div>
                <div className="card-body text-white">
                  <h6 className="text-muted text-white text-center">
                    <span className="badge badge-pill badge-md badge-success mr-1">
                      Coming soon
                    </span>
                    <br />
                    {/* <span className="badge badge-pill badge-success mr-1">SAVE 10%</span> */}
                    <span>Starting at</span>
                  </h6>
                  <div className="display-2 d-flex justify-content-center ">
                    {' '}
                    <span className="font-weight-bold ">20,000</span>
                    <span
                      className="font-weight-400 pt-3 text-left"
                      style={{ fontSize: '16px', lineHeight: '1' }}
                    >
                      RWF <br />
                      Monthly
                    </span>
                  </div>
                  <ul className="list-unstyled my-2 text-white">
                    {/* <li className="align-items-center text-white">
          Renews at 30,000 RWF/months
        </li> */}
                    <button className="btn btn-white btn-round btn-lg w-100 disabled">
                      Coming soon
                    </button>
                  </ul>
                </div>
                <div className="card-footer text-center bg-transparent">
                  <ul className="list-group simple-list mb-3 d-none d-md-flex">
                    <span
                      className="mb-2 text-success tooltipped"
                      title="Dedicated Support"
                      data-toggle="popover"
                      data-content="<p className='my-0 f-8px'>A Dedicated Support expert will always monitor your hosting and help you manage your hosting needs and goals so you can focus on your business.</p>"
                    >
                      <i className="fas fa-star "></i> Dedicated Support{' '}
                      <i className="fas text-light fa-info-circle"></i>
                    </span>

                    <span
                      className="mb-2 text-success tooltipped"
                      title="Dedicated IP address"
                      data-toggle="popover"
                      data-content="<p className='my-0 f-8px'>Benefit from Higher On-Demand Website Access, Improve Email Deliverability, High Level of Security, High Level of Uptime with a Dedicated IP address on this package.</p>"
                    >
                      <i className="fas fa-star "></i> Dedicated IP address{' '}
                      <i className="fas fa-info-circle text-light"></i>
                    </span>
                  </ul>

                  {/* For better Accessibility on mobile */}
                  <ul className="list-group simple-list mb-3 d-md-none">
                    <span
                      className="mb-2 text-success tooltipped"
                      title="Dedicated Support"
                      data-toggle="popover"
                      data-content="<p className='my-0 f-8px'>A Dedicated Support expert will always monitor your hosting and help you manage your hosting needs and goals so you can focus on your business.</p>"
                    >
                      <i className="fas fa-star "></i> Dedicated Support{' '}
                      <i className="fas text-light fa-info-circle"></i>
                    </span>

                    <span
                      className="mb-2 text-success tooltipped"
                      title="Dedicated IP address"
                      data-toggle="popover"
                      data-content="<p className='my-0 f-8px'>Benefit from Higher On-Demand Website Access, Improve Email Deliverability, High Level of Security, High Level of Uptime with a Dedicated IP address on this package.</p>"
                    >
                      <i className="fas fa-star "></i> Dedicated IP address{' '}
                      <i className="fas fa-info-circle text-light"></i>
                    </span>
                  </ul>
                  {/* For better Accessibility on mobile */}
                  <hr className="my-1" />
                  <Link to="" type="button" className="btn btn-link text-white">
                    See all Features
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

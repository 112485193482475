import React, { useEffect, useState } from 'react';
import ls from 'localstorage-slim';
import { Link } from 'react-router-dom';
import { TopCountryBtn } from './TopCountryBtn';
import { useSelector, useDispatch } from 'react-redux';
import { GetCurrencies } from '../redux/actions/currency';

export const HomeHeader = () => {
  const dispatch = useDispatch();
  const { currencies, default_currency, message } = useSelector(
    (state) => state.currency
  );
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    dispatch(GetCurrencies());
  }, []);

  useEffect(() => {
    let current_currency_details = ls.get('current_currency', {
      decrypt: true,
    });
    if (current_currency_details) {
      let newObj = {};
      newObj['code'] = current_currency_details;

      currencies.currencies !== undefined &&
        currencies.currencies.length > 0 &&
        currencies.currencies
          .filter((currency) => currency.code === current_currency_details)
          .map((filteredCurrency) => {
            newObj['rate'] = parseFloat(filteredCurrency.rate);
          });
      setCurrentCurrency(newObj);
    }
  }, [currencies]);
  return (
    <>
      <header className="header-5">
        <nav
          id="navbar-main"
          className="fixed-top navbar navbar-expand-lg navbar-main"
        >
          <div className="container">
            <Link className="navbar-brand mr-lg-5" to="/">
              <img src="./assets/images/cloudhost-white.png" alt="" />
            </Link>
            <div
              className={`navbar-collapse collapse justify-content-around ${
                showNavbar === true ? 'show' : ''
              }`}
              id="navbar_global"
            >
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link to="">
                      <img
                        src="/assets/images/cloudhost-dark.png"
                        height="35"
                        alt="Logo Impact"
                      />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <Link
                      to=""
                      onClick={() => setShowNavbar(false)}
                      role="button"
                      className="fas fa-times"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></Link>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover justify-content-center">
                <li className="nav-item">
                  <Link
                    to="/buy-domain"
                    className="nav-link text-white font-weight-bold text-uppercase"
                  >
                    Buy Domains
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/transfer-domain"
                    className="nav-link text-white font-weight-bold text-uppercase"
                  >
                    Transfer Domains
                  </Link>
                </li>
              </ul>
              {/* <ul className="navbar-nav navbar-nav-hover justify-content-center">
                <li className="nav-item">
                  <Link
                    to=""
                    className="nav-link text-white font-weight-bold text-uppercase"
                  >
                    Shared Hosting
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to=""
                    className="nav-link text-white font-weight-bold text-uppercase"
                  >
                    Domains
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to=""
                    className="nav-link text-white font-weight-bold text-uppercase dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                  >
                    <i className="ni ni-ui-04 d-lg-none"></i>
                    <span className="nav-link-inner--text">Pro Services</span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-xl">
                    <div className="dropdown-menu-inner">
                      <Link to="" className="media d-flex align-items-center">
                        <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                          <i className="ni ni-spaceship"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="heading text-primary mb-md-1 font-weight-bold">
                            Design Services
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0 mt-0">
                            Learn how to use compiling Scss, change brand colors
                            and more.
                          </p>
                        </div>
                      </Link>
                      <Link to="" className="media d-flex align-items-center">
                        <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                          <i className="ni ni-spaceship"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="heading text-primary mb-md-1 font-weight-bold">
                            Custom Web Development Services
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0 mt-0">
                            Learn how to use compiling Scss, change brand colors
                            and more.
                          </p>
                        </div>
                      </Link>
                      <Link to="" className="media d-flex align-items-center">
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-palette"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="heading text-primary mb-md-1 font-weight-bold">
                            Marketing Services
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0 mt-0">
                            Learn more about colors, typography, icons and the
                            grid system we used for .
                          </p>
                        </div>
                      </Link>
                      <Link to="" className="media d-flex align-items-center">
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-palette"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="heading text-primary mb-md-1 font-weight-bold">
                            SEO Marketing Services
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0 mt-0">
                            Learn more about colors, typography, icons and the
                            grid system we used for .
                          </p>
                        </div>
                      </Link>
                      <Link to="" className="media d-flex align-items-center">
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-palette"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="heading text-primary mb-md-1 font-weight-bold">
                            Website Management Sevices
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0 mt-0">
                            Learn more about colors, typography, icons and the
                            grid system we used for .
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul> */}
            </div>
            {/* <TopCurrencyBtn
              currencies={currencies}
              current_currencies={currentCurrency}
            /> */}
            <TopCountryBtn />
            <div className="d-flex d-lg-none align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                onClick={() => setShowNavbar(true)}
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-bars white-burger-bar"></i>
              </button>
            </div>
          </div>
        </nav>
        {/* End Navbar */}

        <div className="page-header mb-0">
          <div className="overlay"></div>
          <div className="container">
            <div className="row align-items-center text-left">
              <div className="col-md-7 col-12">
                <h1 className="display-1 d-none d-md-block text-white">
                  {' '}
                  Buy Web hosting in{' '}
                  {default_currency !== null &&
                  default_currency.hasOwnProperty('code')
                    ? default_currency.country
                    : '...'}{' '}
                </h1>
                <h1 className="mt-6 d-md-none d-block text-white">
                  {' '}
                  Buy Web hosting in{' '}
                  {default_currency !== null &&
                  default_currency.hasOwnProperty('code')
                    ? default_currency.country
                    : '...'}{' '}
                </h1>
                <h2 className="text-white d-none d-md-block">
                  Free Domain for Life name included
                </h2>
                <h4 className="text-white d-md-none d-block">
                  Free Domain for Life name included
                </h4>
                <p className="pb-4 text-white mt-0">
                  We provide reliable, fast and secure Web hosting services with
                  Premium Customer service of over 15+ years of experience.
                </p>
              </div>
              <div id="promo-slider" className="col-lg-5 col-12 mt--5">
                <Link to="" className="">
                  <img
                    className="w-100"
                    src="./assets/images/domainFree.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

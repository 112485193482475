import axios from '../../axios';
import ls from 'localstorage-slim';
import { types } from './types';
import { setErrors, clearErrors } from './errors';
import { openLoader, closeLoader } from './loader';

export const clearMessage = () => {
  return {
    type: types.CLEAR_MESSAGE,
  };
};

// Action for fetching products
export const GetProducts = (payload) => async (dispatch) => {
  let lang = 'en';
  dispatch(clearErrors());
  clearMessage();
  try {
    const response = await axios.get(`api/get-product/${lang}`);
    dispatch({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

import React from 'react';
import ls from 'localstorage-slim';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { faqs } from '../constants';

export const FAQ = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <section className="cd-section bg-primary">
        <div className="accordion-1">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mx-auto text-center">
                <h2 className="title mt-5 text-white font-weight-bold">
                  Frequently asked question
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ml-auto">
                <div className="accordion mt-4" id="accordionExample">
                  {/* {faqs.map((faq) => (
                    <div className="card" ref={faq.id}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className={`btn btn-link w-100 text-primary text-left collapsed ${
                              open === true ? 'show' : ''
                            }`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded={open}
                            aria-controls="collapseOne"
                            onClick={() => {
                              setOpen(!open);
                            }}
                          >
                            {faq.title}
                            <i className="ni ni-bold-down float-right pt-1"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        className={`collapse ${open === true ? 'show' : ''}`}
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body opacity-8">
                          {faq.description}
                        </div>
                      </div>
                    </div>
                  ))} */}
                  {faqs.map((faq) => (
                    <Accordion style={{ width: '100%' }} key={faq.id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'uppercase',
                          }}
                          className="title text-primary"
                        >
                          {faq.title}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-muted"> {faq.description}</span>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import axios from '../../axios';
import ls from 'localstorage-slim';
import { types } from './types';
import { setErrors, clearErrors } from './errors';
import {
  openLoader,
  closeLoader,
  openInnerLoader,
  closeInnerLoader,
} from './loader';

export const clearMessage = () => {
  return {
    type: types.CLEAR_MESSAGE,
  };
};

// Action for fetching order
export const GetOrderDraft = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  dispatch(openInnerLoader());
  try {
    const response = await axios.post(
      'api/getOrderDraft',
      { draft_id: payload },
      {
        headers: {
          auth_token: ls.get('token', { decrypt: true }),
        },
      }
    );
    dispatch({
      type: types.GET_ORDER_SUCCESS,
      payload: response.data,
    });
    dispatch(closeInnerLoader());
  } catch (error) {
    dispatch(closeInnerLoader());
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for creating order draft
export const CreateOrderDraft = () => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();

  try {
    const response = await axios.post(`api/createOrderDraft`);
    dispatch({
      type: types.CREATE_ORDER_DRAFT_SUCCESS,
      payload: response.data.draft_id,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for adding order draft item
export const AddOrderDraftItem = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  dispatch(openInnerLoader());
  try {
    let service_res = null;
    let domain_res = null;
    switch (payload.requests) {
      case 'all':
        service_res = await axios.post(`api/addOrderDraftItem`, {
          ...payload,
          prod_type: 'service',
        });
        domain_res = await axios.post(`api/addOrderDraftItem`, {
          ...payload,
          prod_type: 'domain',
        });
        break;
      case 'service':
        service_res = await axios.post(`api/addOrderDraftItem`, {
          ...payload,
          prod_type: 'service',
        });
        break;
      case 'domain':
        service_res = await axios.post(`api/addOrderDraftItem`, {
          ...payload,
          prod_type: 'domain',
        });
        break;
      default:
        console.error('Invalid request');
    }
    const data = {
      service_res: service_res,
      domain_res: domain_res,
    };
    dispatch({
      type: types.ADD_ORDER_DRAFT_ITEM_SUCCESS,
      payload: data,
    });
    dispatch(closeInnerLoader());
  } catch (error) {
    dispatch(closeInnerLoader());
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for edit order draft
export const EditOrderDraft = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  try {
    const response = await axios.post('api/editOrderDraft', payload, {
      headers: {
        auth_token: ls.get('token', { decrypt: true }),
      },
    });
    dispatch({
      type: types.EDIT_ORDER_DRAFT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for converting order draft
export const ConvertOrderDraft = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(openLoader());
  clearMessage();
  try {
    const response = await axios.post('api/convertOrderDraft', payload, {
      headers: {
        auth_token: ls.get('token', { decrypt: true }),
      },
    });
    dispatch({
      type: types.CONVERT_ORDER_DRAFT_SUCCESS,
      payload: response.data,
    });
    dispatch(closeLoader());
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
    dispatch(closeLoader());
  }
};

// Action for removing order draft
export const RemoveOrderDraft = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  try {
    const response = await axios.post('api/removeOrderDraft', payload, {
      headers: {
        auth_token: ls.get('token', { decrypt: true }),
      },
    });
    dispatch({
      type: types.REMOVE_ORDER_DRAFT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for removing order draft Item
export const RemoveOrderDraftItem = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  dispatch(openInnerLoader());
  try {
    const response = await axios.post('api/removeOrderDraftItem', payload, {
      headers: {
        auth_token: ls.get('token', { decrypt: true }),
      },
    });
    dispatch({
      type: types.REMOVE_ORDER_DRAFT_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for making payments
export const MakePayment = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  dispatch(openInnerLoader());
  try {
    const response = await axios.post('api/payment/make', payload, {
      headers: {
        auth_token: ls.get('token', { decrypt: true }),
      },
    });
    dispatch({
      type: types.MAKE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};

// Action for making payments
export const CheckPaymentStatus = (payload) => async (dispatch) => {
  dispatch(clearErrors());
  clearMessage();
  dispatch(openInnerLoader());
  try {
    const response = await axios.post(
      `${payload}`,
      { data: {} },
      {
        headers: {
          auth_token: ls.get('token', { decrypt: true }),
        },
      }
    );
    dispatch({
      type: types.CHECK_PAYMENT_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      setErrors({
        error: error.response.data.errors,
        error_msg: error.response.data.message,
      })
    );
  }
};
